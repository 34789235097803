import React from "react";
//import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { Nav, Container, Navbar } from "react-bootstrap";
import { Link } from 'react-router-dom'
import API from "../../common/api";
import { Badge } from "antd";
import "antd/dist/reset.css";
import moment from "moment";
// import { AC_COUNT_TRIPS } from "../../actions/accountaction";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false,
    };
  }
  getNbHourBeforeTrips = (checkin) => {
    // Recupération de la date de début
    let currentDate = new Date();
    currentDate = moment(currentDate, "DD-MM-YYYY HH:mm");
    let checkInFormatted = moment(checkin, "DD-MM-YYYY HH:mm");
    let hours = checkInFormatted.diff(currentDate, "hours");
    return hours;
  };
  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });    
  };

  closeNav = () => {    
    this.setState({ navExpanded: false });
  };

  detectRequest = () => {
    let tripsList = this.props.AccountReducer.tripsListRequest;
    let countAsk = 0;
    // let countRental = 0;

    for (let i in tripsList) {
      if (
        tripsList[i]?.customerId &&
        this.getNbHourBeforeTrips(tripsList[i]?.checkin) >= 0 && tripsList[i]?.tripStatus === 1
      ) {
        // Demande acceptée en cours de paiement
        countAsk = 1;
        break;
      }
    }
    return countAsk;
  }
  detectRental = () => {
    let tripsList = this.props.AccountReducer.tripsList;
    let countRental = 0;

    for (let i in tripsList) {
      if (
        tripsList[i]?.customerId &&
        this.getNbHourBeforeTrips(tripsList[i]?.checkin) >= 0 && (tripsList[i]?.tripStatus === 3 || tripsList[i]?.tripStatus === 1)
      ) {
        // Demande acceptée en cours de paiement          
        countRental = 1
        break;
      }
    }

    return countRental

  }
  detectTrips = () => {    
    return this.detectRental() + this.detectRequest()
  }

  // count() {
  //   let tripsList = this.props.AccountReducer.tripsList;
  //   let tripsListRequest = this.props.AccountReducer.tripsListRequest;
  //   // this.props.CountTrips()
  //   //console.log(tripsList);
  //   let countAsk = 0;
  //   let countRental = 0;

  //   for (let i in tripsList) {
  //     if (
  //       tripsList[i]?.customerId &&
  //       this.getNbHourBeforeTrips(tripsList[i]?.checkin) >= 0 && tripsList[i]?.tripStatus === 1
  //     ) {
  //       if (tripsList[i]?.tripStatus === 1) {
  //         countAsk++;
  //       } else if (tripsList[i]?.tripStatus === 3) {
  //         countRental++;
  //       }
  //     }
  //   }

  //   return [countAsk, countRental];
  // }

  render() {
    const { t } = this.props;
    const sitedata = this.props.DashboardReducer.settings;
    //console.log("set", sitedata);
    let logo =
      "https://res.cloudinary.com/monbolide/image/upload/c_scale,w_324/v1651870247/monbolide_logo_zfsm9u.jpg";
    const isAuth = localStorage.getItem("siteUser");
    if (sitedata?.site) {
      if (sitedata?.site?.logo) {
        logo = API.IMAGEURL + sitedata?.site?.logo;
      }
    }

    const userInfo = this.props.AccountReducer.accountInfo;

    const form = {
      aboutme: userInfo.aboutme,
      address: userInfo.address,
      dateofbirth: userInfo.dateofbirth,
      email: userInfo.email,
      firstname: userInfo.firstname,
      image: userInfo.image,
      issuingcountry: userInfo.issuingcountry,
      lastname: userInfo.lastname,
      licensenumber: userInfo.licensenumber,
      /* linkedin: userInfo.linkedin, */
      mobilenumber: userInfo.mobilenumber,
      /*  twitter: userInfo.twitter, */
    };

    let cpteUserInfo = 0;

    for (let i in form) {

      if (form[i] === undefined || form[i] === "") {
        cpteUserInfo++;
      }
    }


    return (
      // <header className="header">
      // <div style={{ height: "58px" }}>
      <Navbar
        onToggle={this.setNavExpanded}
        sticky="top"
        expanded={this.state.navExpanded}
        collapseOnSelect
        expand="lg"
        className="px-2 smart-scroll"
        // onMouseOut={() => this.closeNav()}
        onMouseLeave={() => this.closeNav()}
        onBlur={() => this.closeNav()}
      >
        
          {/* <Navbar.Brand
            onClick={() => this.closeNav()}
            as={Link} to="/"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 1.2 + "%",
              paddingBottom: 1.2 + "%"
            }}
          /> */}
          <Container className="container zero-padding">
              <Navbar.Brand
              onClick={() => this.closeNav()}
                as={Link} to="/"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 1.2 + "%",
                  paddingBottom: 1.2 + "%"
                }}
              >
                <img
                  src={logo}
                  alt=""
                  width="169px"
                  height="auto"
                  style={{ objectFit: "contain" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav id="navbarText" className="ml-auto">
                  {isAuth ? (
                    <>
                      <Nav.Link
                        as={Link} to="/profile"
                        onClick={() => this.closeNav()}
                        
                      >
                        <Badge
                          count={cpteUserInfo}                          
                          offset={[5, -3]}
                          dot={true}
                        >
                          {t("profile")}
                        </Badge>
                      </Nav.Link>

                      <Nav.Link
                        as={Link} to="/booking-request"                        
                        onClick={() => this.closeNav()}
                      >
                        <Badge
                          count={this.detectTrips()}
                          offset={[5, -3]}
                          dot={true}                          
                        >
                          {t("accounts")}
                        
                        </Badge>
                      </Nav.Link>
                      {/* <Nav.Link
                        as={Link} to="/messaging"
                        onClick={() => this.closeNav()}
                        
                      >
                        <Badge
                          count={0} offset={[10, -5]}                                                    
                        >
                          {t("messaging")}
                        </Badge>
                      </Nav.Link> */}
                    </>
                  ) : (
                    <>
                      <Nav.Link as={Link} to="/sign-up" onClick={() => this.closeNav()}>
                        
                        <Badge count={0} offset={[10, -5]}>
                          {t("sign-up")}
                        </Badge>
                      </Nav.Link>
                      <Nav.Link as={Link} to="/sign-in" onClick={() => this.closeNav()}>
                        <Badge count={0} offset={[10, -5]}>
                          {t("sign-in")}
                        </Badge>
                      </Nav.Link>
                    </>
                  )}

                  {/* <Nav.Link as={Link} to="/business">
                    <Badge count={0} offset={[10, -5]}>
                      {t("business")}
                    </Badge>
                  </Nav.Link> */}
                  <Nav.Link as={Link} to="/questions" onClick={() => this.closeNav()}>
                    <Badge count={0} offset={[10, -5]}>
                      {t("any-questions")}
                    </Badge>
                  </Nav.Link>
                  <Nav.Link
                  style={{fontFamily: "Poppins", background: "#004aad", borderRadius: "12px", border: "none"}}
                  onClick={() => this.closeNav()}
                  as={Link} to="/hire-out-car"                    
                    className="form-rent-btn rentBtn text-white navbar-space"
                    // style={{ display: "flex" }}
                  >
                    {t("hire-out-your-car")}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
        
      </Navbar >

      // </div>
      // </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    AccountReducer: state.AccountReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ }, dispatch);
}
const HeaderComponent = withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);

export default HeaderComponent;
