import React from "react";
import { Redirect } from "react-router-dom";
import { Imagevalidation } from "../../common/validate";
import { connect } from "react-redux";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import { bindActionCreators } from "redux";
import {
  AC_VIEW_HOST_DETAILS,
  AC_HANDLE_INPUT_CHANGE,
  AC_UPDATE_HOST_DETAILS,
} from "../../actions/hostcaraction";
import API from "../../common/api";
import "./Addcarimage.css";
const override = css`
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
class AddCarImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      previous: false,
      next: false,
      mainimage: this.props.HostCarReducer?.carImageInfo?.mainimage
        ? false
        : true,
      sideimage: this.props.HostCarReducer?.carImageInfo?.sideimage
        ? false
        : true,
      backimage: this.props.HostCarReducer?.carImageInfo?.backimage
        ? false
        : true,
      interiorimage: this.props.HostCarReducer?.carImageInfo?.interiorimage
        ? false
        : true,
      extraimage: this.props.HostCarReducer?.carImageInfo?.extraimage
        ? false
        : true,
      mainimageerror: false,
      sideimageerror: false,
      backimageerror: false,
      interiorimageerror: false,
      extraimageerror: false,
      mainimageSrc: "",
      sideimageSrc: "",
      backimageSrc: "",
      interiorimageSrc: "",
      extraimageSrc: "",
    };
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  validateForm() {
    
    const checkdata = this.state;
    const carImageInfo = this.props.HostCarReducer.carImageInfo;
    // const userEmail = this.props.AccountReducer.accountInfo.email;
    // const userInfo = this.props.AccountReducer.accountInfo;
    // console.log(userInfo)
    const id = localStorage.getItem("formId");

    let countError = 0;

    if (carImageInfo) {
      
      // console.log(carImageInfo)
      if (carImageInfo.mainimage) {
        this.setState({ mainimage: false });
      } else {
        this.setState({ mainimage: true });
        countError++;
      }

      if (carImageInfo.sideimage) {
        this.setState({ sideimage: false });
      } else {
        this.setState({ sideimage: true });
        countError++;
      }

      if (carImageInfo.backimage) {
        this.setState({ backimage: false });
      } else {
        this.setState({ backimage: true });
        countError++;
      }

      if (carImageInfo.interiorimage) {
        this.setState({ interiorimage: false });
      } else {
        this.setState({ interiorimage: true });
        countError++;
      }

      if (carImageInfo.extraimage) {
        this.setState({ extraimage: false });
      } else {
        this.setState({ extraimage: true });
        countError++;
      }
      if (
        countError === 0 &&
        !this.state.mainimageerror &&
        !checkdata.backimageerror &&
        !checkdata.sideimageerror &&
        !checkdata.interiorimageerror &&
        !checkdata.extraimageerror
      ) {
        this.setState({loading: true})
        const formData = new FormData();
        for (const image in carImageInfo) {
          formData.append(image, carImageInfo[image]);
        }
        // formData.append("email", userInfo.email);
        // formData.append("fullName", userInfo.firstname + " " + user);

        // console.log(formData)
        // console.log(formData.get("userInfo"))
        this.props.updateHostDetails(formData, id, "CarImage").then(()=> {
          this.setState({
            loading: false,
            next: true
          })          
        }).catch((e)=> {
          Error("Une erreur s'est produite")
          this.setState({loading: false})
        });
        
      }

      // if (!this.state.mainimage && !this.state.sideimage && !this.state.extraimage && !this.state.backimage && !this.state.interiorimage && ) {

      //   console.log('Ok')
      // }
    } else {
      this.setState({
        mainimage: true,
        sideimage: true,
        interiorimage: true,
        backimage: true,
        extraimage: true,
      });
    }
  }

  previous() {
    this.setState({ previous: true });
  }
  componentDidMount() {
    if (localStorage.getItem("formId")) {
      this.props.viewDetails(localStorage.getItem("formId"));
    }
    // const userInfo = 
  }
  onChangeValue(event) {
    const name = event.target.name;
    const error = name + "error";
    const imgSrc = name + "Src";
    var file = event.target.files[0];
    const iconvalidate = Imagevalidation(file);
    if (name === "mainimage") {
      this.setState({ [name]: false });
    }
    if (name === "sideimage") {
      this.setState({ [name]: false });
    }
    if (name === "backimage") {
      this.setState({ [name]: false });
    }
    if (name === "interiorimage") {
      this.setState({ [name]: false });
    }
    if (name === "extraimage") {
      this.setState({ [name]: false });
    }

    if (iconvalidate) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        this.setState({ [error]: false, [imgSrc]: [reader.result] });
      }.bind(this);
      this.props.handleInputChange(name, file, "carImage");
    } else {
      this.setState({ [error]: true });
    }
  }
  render() {
    // console.log('-----------------Information sur le compte--------------------')
    // console.log(this.props.AccountReducer.accountInfo)
    // console.log('-----------------Information sur le compte--------------------')
    // this.props.AccountReducer.accountInfo;
    const next = this.state.next;
    const previous = this.state.previous;
    if (previous) {
      return <Redirect push={true} to="/customer-care" />;
    }
    if (next) {
      return <Redirect to="/ready-to-rent" />;
    }
    let mainimage = "https://res.cloudinary.com/monbolide/image/upload/f_auto/q_auto:eco/v1643000945/ProjectImage/images/your-cars/car-1_jxrzqw.jpg"
    let sideimage = "https://res.cloudinary.com/monbolide/image/upload/f_auto/q_auto:eco/v1643000945/ProjectImage/images/your-cars/car-2_jg04p7.jpg"
    let backimage = "https://res.cloudinary.com/monbolide/image/upload/f_auto/q_auto:eco/v1643000945/ProjectImage/images/your-cars/car-3_osgmre.jpg"
    let interiorimage = "https://res.cloudinary.com/monbolide/image/upload/f_auto/q_auto:eco/v1643000945/ProjectImage/images/your-cars/car-4_t1gya2.jpg"
    let extraimage = "https://res.cloudinary.com/monbolide/image/upload/f_auto/q_auto:eco/v1643000945/ProjectImage/images/your-cars/car-1_jxrzqw.jpg"
    const carImageInfo = this.props.HostCarReducer.carImageInfo;
    if (carImageInfo) {
      if (carImageInfo.mainimage) {
        mainimage = API.IMAGEURL + carImageInfo.mainimage;
      }
      if (carImageInfo.sideimage) {
        sideimage = API.IMAGEURL + carImageInfo.sideimage;
      }
      if (carImageInfo.backimage) {
        backimage = API.IMAGEURL + carImageInfo.backimage;
      }
      if (carImageInfo.interiorimage) {
        interiorimage = API.IMAGEURL + carImageInfo.interiorimage;
      }
      if (carImageInfo.extraimage) {
        extraimage = API.IMAGEURL + carImageInfo.extraimage;
      }
    }
    return (
      <div>
        {this.state.loading && (
          <div className="loading">
            <BounceLoader
              color="#004aad"
              loading={this.state.loading}
              css={override}
              size={50}
            />
          </div>
        )}
        <section className="content-section">
        <div className="container">
          <div className="card-part">
            <div className="row">
              <div className="col-lg-12">
                <div className="card_section">
                  <div className="card-title">
                    <h2>Photos du véhicule</h2>
                    <p className="mb-24">
                      Nous n'affichons que les voitures avec photos. Vous pouvez{" "}
                      <b>commencer par un</b> et en ajouter d'autres une fois
                      que votre annonce est en ligne.
                    </p>
                    <h3 className="sub-title">Nos conseils</h3>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 flex_row_center">
                      <span className="tick-icon">
                        <i className="fa fa-check-circle tick-icon-size"></i>
                      </span>
                      <p className="mb-0">Utilisez le format paysage</p>
                    </div>
                    <div className="col-lg-6 flex_row_center">
                      <span className="tick-icon">
                        <i className="fa fa-check-circle tick-icon-size"></i>
                      </span>
                      <p className="mb-0">
                        Suivez nos directives en matière d'angle
                      </p>
                    </div>
                    <div className="col-lg-6 flex_row_center">
                      <span className="tick-icon">
                        <i className="fa fa-check-circle tick-icon-size"></i>
                      </span>
                      <p className="mb-0">
                        Veillez à ce que l'arrière-plan soit clair et neutre
                      </p>
                    </div>
                    <div className="col-lg-6 flex_row_center">
                      <span className="tick-icon">
                        <i className="fa fa-check-circle tick-icon-size"></i>
                      </span>
                      <p className="mb-0">
                        Utiliser uniquement la lumière naturelle du jour
                      </p>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="main_photo_section">
                    <h3 className="main-title">
                      Photo principale <span className="text-danger">*</span>
                    </h3>
                    <div className="main-mb-24">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="browse-wrapper">
                            <label className="browse-placeholder mb-0">
                              <input
                                type="file"
                                style={{ visibility: "hidden" }}
                                className="browse-upload"
                                name="mainimage"
                                onChange={(e) => this.onChangeValue(e)}
                              />
                              <div className="browse-content">
                                <div className="browse-text">3/4 avant</div>
                                <i className="fa fa-plus-circle plus-icon-size"></i>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div
                            className="car-container"
                            style={{ height: "100%" }}
                          >
                            {this.state.mainimage && (
                              <div className="car-text">
                                <p>
                                  <b>Une photo 3/4 avant</b> qui se distingue :
                                  c'est le premier que les conducteurs voient.
                                </p>
                              </div>
                            )}
                            <div className="car-pic">
                              {/*<img src="assets/images/your-cars/car-1.jpg"/>*/}
                              {this.state.mainimageSrc ? (
                                <img
                                  src={this.state.mainimageSrc}
                                  alt="Principale"
                                />
                              ) : mainimage ? (
                                <img src={mainimage} alt="Principale" />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.mainimage ? (
                        <label className="text-danger">
                          L'image est requise
                        </label>
                      ) : (
                        ""
                      )}
                      {this.state.mainimageerror ? (
                        <label className="text-danger">
                          Entrez l'image (.jpg/.png seulement)
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <hr className="car-divider" />
                  <div className="main_photo_section">
                    <h3 className="main-title">
                      Photos supplémentaires
                      <span className="text-danger">*</span>
                    </h3>
                    <div className="main-mb-24">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="browse-wrapper">
                            <label className="browse-placeholder mb-0">
                              <input
                                type="file"
                                style={{ visibility: "hidden" }}
                                className="browse-upload"
                                name="sideimage"
                                onChange={(e) => this.onChangeValue(e)}
                              />
                              <div className="browse-content">
                                <div className="browse-text">side</div>
                                <i className="fa fa-plus-circle plus-icon-size"></i>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          style={{ MoveRight: "100px" }}
                        >
                          <div className="car-container">
                            {this.state.sideimage && (
                              <div className="car-text">
                                <p>
                                  <b>Une photo de côté</b> pour donner une idée
                                  de la taille de votre voiture.
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="car-pic">
                            {this.state.sideimageSrc ? (
                              <img
                                src={this.state.sideimageSrc}
                                alt="Profile"
                              />
                            ) : (
                              <img src={sideimage} alt="Profile" />
                            )}
                          </div>
                        </div>
                      </div>
                      {this.state.sideimage ? (
                        <label className="text-danger">
                          Une image de profil est requise
                        </label>
                      ) : (
                        ""
                      )}
                      {this.state.sideimageerror ? (
                        <label className="text-danger">
                          Entrez l'image (.jpg/.png seulement)
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <hr className="car-divider" />
                  <div className="main_photo_section">
                    <h3 className="main-title">
                      Photos Arrière<span className="text-danger">*</span>
                    </h3>
                    <div className="main-mb-24">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="browse-wrapper">
                            <label className="browse-placeholder mb-0">
                              <input
                                type="file"
                                style={{ visibility: "hidden" }}
                                className="browse-upload"
                                name="backimage"
                                onChange={(e) => this.onChangeValue(e)}
                              />
                              <div className="browse-content">
                                <div className="browse-text">Dos 3/4</div>
                                <i className="fa fa-plus-circle plus-icon-size"></i>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="car-container">
                            {this.state.backimage && (
                              <div className="car-text">
                                <p>
                                  <b>Une photo 3/4 arrière</b> pour terminer
                                  l'aperçu extérieur.
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="car-pic">
                            {this.state.backimageSrc ? (
                              <img src={this.state.backimageSrc} alt="Back" />
                            ) : (
                              <img src={backimage} alt="Back" />
                            )}
                          </div>
                        </div>
                      </div>
                      {this.state.backimage ? (
                        <label className="text-danger">
                          Une image arrière est requise
                        </label>
                      ) : (
                        ""
                      )}
                      {this.state.backimageerror ? (
                        <label className="text-danger">
                          Entrez l'image (.jpg/.png seulement)
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <hr className="car-divider" />
                  <div className="main_photo_section">
                    <h3 className="main-title">
                      Photos intérieure<span className="text-danger">*</span>
                    </h3>

                    <div className="main-mb-24">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="browse-wrapper">
                            <label className="browse-placeholder mb-0">
                              <input
                                type="file"
                                style={{ visibility: "hidden" }}
                                className="browse-upload"
                                name="interiorimage"
                                onChange={(e) => this.onChangeValue(e)}
                              />
                              <div className="browse-content">
                                <div className="browse-text">Intérieur</div>
                                <i className="fa fa-plus-circle plus-icon-size"></i>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="car-container">
                            {this.state.interiorimage && (
                              <div className="car-text">
                                <p>
                                  <b>Une photo d'intérieur</b> pour aider les
                                  conducteurs à s'imaginer au volant.
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="car-pic">
                            {this.state.interiorimageSrc ? (
                              <img src={this.state.interiorimageSrc} alt="" />
                            ) : (
                              <img src={interiorimage} alt="" />
                            )}
                          </div>
                        </div>
                      </div>
                      {this.state.interiorimage ? (
                        <label className="text-danger">
                          Une image intérieure est requise
                        </label>
                      ) : (
                        ""
                      )}
                      {this.state.interiorimageerror ? (
                        <label className="text-danger">
                          Entrez l'image (.jpg/.png seulement)
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <hr className="car-divider" />
                  <div className="main_photo_section">
                    <h3 className="main-title">
                      Photos Supplémentaire
                      <span className="text-danger">*</span>
                    </h3>
                    <div className="main-mb-24">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="browse-wrapper">
                            <label className="browse-placeholder mb-0">
                              <input
                                type="file"
                                style={{ visibility: "hidden" }}
                                className="browse-upload"
                                name="extraimage"
                                onChange={(e) => this.onChangeValue(e)}
                              />
                              <div className="browse-content">
                                <div className="browse-text">Extra</div>
                                <i className="fa fa-plus-circle plus-icon-size"></i>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="car-container">
                            {this.state.extraimage && (
                              <div className="car-text">
                                <p>
                                  <b>Photos supplémentaires</b> pour mettre en
                                  évidence les spécificités (siège bébé,
                                  GPS...).pour terminer l'aperçu extérieur.
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="car-pic">
                            {this.state.extraimageSrc ? (
                              <img
                                src={this.state.extraimageSrc}
                                alt="exterior"
                              />
                            ) : extraimage ? (
                              <img src={extraimage} alt="exterior" />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      {this.state.extraimage ? (
                        <label className="text-danger">
                          Une dernière image est requise
                        </label>
                      ) : (
                        ""
                      )}
                      {this.state.extraimageerror ? (
                        <label className="text-danger">
                          Entrez l'image (.jpg/.png seulement)
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="section-divided">
                    <div className="align_right">
                      <div className="prev-button-item">
                        <button
                          className="prev-button mr-2"
                          onClick={this.previous}
                        >
                          <span className="cobalt-Icon">
                            <svg
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                            </svg>
                          </span>
                          Précédent
                        </button>
                      </div>
                      <div className="save-button-item">
                        <button
                          className="Woohoo-btn"
                          onClick={this.validateForm}
                        >
                          Suivant
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
      
    );
  }
}
function mapStateToProps(state) {
  return {
    HostCarReducer: state.HostCarReducer,
    AccountReducer: state.AccountReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      viewDetails: AC_VIEW_HOST_DETAILS,
      handleInputChange: AC_HANDLE_INPUT_CHANGE,
      updateHostDetails: AC_UPDATE_HOST_DETAILS,
    },
    dispatch
  );
}
const AddCarImageComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCarImage);

export default AddCarImageComponent;
