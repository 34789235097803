import React from "react";

class DiscoverSection extends React.Component {
  render() {
    return (
      <section className="discover-section">
        <div className="container">
          <div className="discover-text">
            <h2 style={{fontFamily:"Poppins"}}>
              Déjà un professionnel ou voulez-vous le devenir avec
              Monbolide ?
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="https://res.cloudinary.com/monbolide/image/upload/v1643000945/ProjectImage/images/get-img/pros_private_hhbgpn.png"
                    alt="pros_private_hhbgpn"
                  />
                </div>
                <div className="card-body">
                  <div className="padding">
                    <p className="card-title">Vous êtes un particulier...</p>
                    <span className="card-subtitle">
                      et vous voulez gagner votre vie en gérant votre propre
                      flotte de voitures sur Monbolide ?
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="https://res.cloudinary.com/monbolide/image/upload/v1643000941/ProjectImage/images/images/pros_entrepreneur-b11d40cfcd076c55f9f68a67fd71b5ae_lmvh2w.png"
                    alt="b11d40cfcd076c55f9f68a67fd71b5ae_lmvh2w"
                  />
                </div>
                <div className="card-body">
                  <p className="card-title">
                    Vous dirigez déjà une agence de location, un garage ou une
                    entreprise de vente de voitures...
                  </p>
                  <span className="card-subtitle">
                    et vous souhaitez optimiser le taux d'occupation de votre
                    flotte ?
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a href="">
              <button type="button" className="discover-btn">
                Découvrez notre offre pour les propriétaires
              </button>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default DiscoverSection;
