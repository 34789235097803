// export const LIVEURL = "http://localhost:5000/";
export const LIVEURL = "https://api.monbolide.com/";
const IMAGEURL =
  "https://res.cloudinary.com/monbolide/image/upload/v1618515576/";

const IMAGEURL_CARTYPE = "https://res.cloudinary.com/monbolide/image/upload/v1659643454/ProjectImage/images/car/types/";

const PREFIX_URL_IMAGE_TO_COMPRESS =
  "https://res.cloudinary.com/monbolide/image/upload/";

const SUFFIX_URL_IMAGE_TO_COMPRESS = "v1618515576/"


// Web Socket
const LOCAL_WEBSOCKET_URL = "https://ws.monbolide.com";
// const LOCAL_WEBSOCKET_URL = "http://localhost:5500";
const ROOTURL = LIVEURL + "api/v1/";
const API = {
  SendContract: ROOTURL + "account/sendContract",
  AddMessage  :  ROOTURL + "chat/addMessage",
  GetMessages : ROOTURL + "chat/getMessages",  
  AddCarImage: ROOTURL + "cars/addImage",
  DashboardList: ROOTURL + "dashboard/dashboard",
  LastAddCars: ROOTURL + "cars/lastFreeCars",
  GetConfigurationValue: ROOTURL + "siteconfiguration/getConfigurationValue",
  getCarModel: ROOTURL + "hostdetails/getModel",
  HostCar: ROOTURL + "hostdetails/hostCar",
  ViewHostDetails: ROOTURL + "hostdetails/viewHostDetails",
  UpdateHostDetails: ROOTURL + "hostdetails/updateHostDetails",
  ViewOwnerAccount: ROOTURL + "account/viewOwnerInfo",
  ViewCustomer: ROOTURL + "account/viewCustomer",
  ViewAccount: ROOTURL + "account/viewYourAccount",
  AccountSettings: ROOTURL + "account/accountSettings",
  EditAccount: ROOTURL + "account/editYourAccount",
  ViewTrips: ROOTURL + "account/viewTrips",
  ViewCarTrips: ROOTURL + "account/viewCarTrips",
  ViewCars: ROOTURL + "account/viewCars",
  ViewYourCar: ROOTURL + "cars/viewYourCar",
  AddUnavailibility: ROOTURL + "cars/addUnavailibility",
  DeleteUnavailibility: ROOTURL + "cars/deleteUnavailibility",
  UpdateCarStatus: ROOTURL + "cars/updateCarStatus",
  DeleteCars: ROOTURL + "account/deleteCars",
  ToggleActiveCar: ROOTURL + "account/toggleActiveCar",
  ViewPages: ROOTURL + "sitepages/viewPages",
  NotificationSettings: ROOTURL + "account/notificationSettings",
  //AddRequireCar: ROOTURL + "cars/addRequireCar",
  EditRequireCar: ROOTURL + "cars/editRequireCar",
  AccountCreation: ROOTURL + "account/accountCreation",
  SendOtpVerification: ROOTURL + "account/sendMessageToVerify",
  OtpVerification: ROOTURL + "account/otpVerification",
  AddUserTemp: ROOTURL + "account/addUserTemp",
  ActiveAccount: ROOTURL + "account/activeAccount",
  Authorization: ROOTURL + "account/isAuth",
  EditNotification: ROOTURL + "account/editNotification",
  ForgotPassword: ROOTURL + "account/forgotPassword",
  ChangePassword: ROOTURL + "account/changePassword",
  searchCar: ROOTURL + "searchcar/searchCar",
  priceFilter: ROOTURL + "searchcar/priceFilter",
  modelCar: ROOTURL + "searchcar/modelCar",
  EditCardetails: ROOTURL + "cars/editCarDetails",
  ViewFaq: ROOTURL + "faqs/viewFaq",
  AddTrips: ROOTURL + "sitetrips/addTrips",
  AddReview: ROOTURL + "sitetrips/addReviews",
  VerifyProfile: ROOTURL + "account/verifyProfile",
  AddAttachmentToProfile: ROOTURL + "account/addAttachmentToProfile",
  FacebookAuth: ROOTURL + "social/facebooklogin",
  GoogleAuth: ROOTURL + "social/googlelogin",
  stripePay: ROOTURL + "pay/cb",
  paydunyaPayTokenSend: ROOTURL + "pay/checkout",
};

export default URL = {
  LIVEURL: LIVEURL,
  API: API,
  IMAGEURL: IMAGEURL,
  IMAGEURL_CARTYPE: IMAGEURL_CARTYPE,
  PREFIX_URL_IMAGE_TO_COMPRESS: PREFIX_URL_IMAGE_TO_COMPRESS,
  SUFFIX_URL_IMAGE_TO_COMPRESS: SUFFIX_URL_IMAGE_TO_COMPRESS,
  LOCAL_WEBSOCKET_URL: LOCAL_WEBSOCKET_URL,
};
