import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import URL from "../../common/api";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { IoIosClose } from "react-icons/io";
import ChatBoxInput from "./ChatBoxInput";
import axios from "axios";

export default function ChatBoxContainer({ currentChat, socket, root, closeChat }) {

    // console.log(currentChat)
    console.log("root", root)
    const [messages, setMessages] = useState([]);
    const [arrivalMessage, setArrivalMessage] = useState(null); // Pour les messages
    const [closed, setClosed] = useState(true)
    const scrollRef = useRef(); // gestion du scroll
    const [connected, setConnected] = useState(false)


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const response = await axios.post(URL.API.GetMessages, {
            from: (root === "rental") ? currentChat?.customerId?._id : currentChat?.ownerId._id,
            to: (root === "rental") ? currentChat?.ownerId._id : currentChat?.customerId._id,
            trips: currentChat?._id
        });
        // console.log("Tous les message du trip")
        setMessages(response.data.data);
    }, [currentChat, root]);

    const handleSendMsg = async (msg) => {
        if (msg.length > 0) {

            const hour =
                new Date().getUTCHours() < 10
                    ? `0${new Date().getUTCHours()}`
                    : `${new Date().getUTCHours()}`;

            const mins =
                new Date().getUTCMinutes() < 10
                    ? `0${new Date().getUTCMinutes()}`
                    : `${new Date().getUTCMinutes()}`;




            socket?.current?.emit("send-msg", {
                from: (root === "rental") ? currentChat?.customerId?._id : currentChat?.ownerId._id,
                to: (root === "rental") ? currentChat?.ownerId._id : currentChat?.customerId._id,
                time: { hour, mins },
                msg,
                trips: currentChat?._id
            });
            await axios.post(URL.API.AddMessage, {
                from: (root === "rental") ? currentChat.customerId?._id : currentChat?.ownerId._id,
                to: (root === "rental") ? currentChat?.ownerId._id : currentChat?.customerId._id,
                message: msg,
                time: { hour, mins },
                trips: currentChat?._id,
                action: root
            });

            const msgs = [...messages];
            const message = {
                fromSelf: true,
                message: msg,
                hour: hour,
                mins: mins
            }
            msgs.push(message);
            setMessages(msgs);
        }
    }

    useEffect(() => {
        if (socket?.current) {
            socket?.current?.on("msg-recieve", ({ msg, time }) => {
                setArrivalMessage({ fromSelf: false, message: msg, hour: time.hour, mins: time.mins });
            });
        }
    }, []);

    useEffect(() => {
        arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
    }, [arrivalMessage]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleCloseChat = () => {
        closeChat(true)
    }
    // db.carlists.updateOne({"parking.location.lon": null}, {$set: {"parking.formatted_address": "Cocody, Abidjan", "parking.location.lat": 5.365935001667557, "parking.location.lon": -3.9550803064452316}})

    return (

        <Container>
            <div className="chat-header">
                <div className="user-details">
                    <div className="avatar">

                        {((root === "rental") && currentChat?.ownerId.image) &&
                            <img src={URL.IMAGEURL + currentChat?.ownerId?.image} alt="" />}

                        {((root !== "rental") && currentChat?.customerId.image) &&
                            <img src={URL.IMAGEURL + currentChat?.customerId?.image} alt="" />}

                        {(!currentChat?.customerId.image || !currentChat?.ownerId?.image) && <img src={"https://res.cloudinary.com/monbolide/image/upload/v1643000942/ProjectImage/images/default_rmfapd.jpg"} alt="" />}

                    </div>
                    <div className="username">
                        <div>
                            {(root === "rental") ? currentChat?.ownerId?.firstname + " " + currentChat?.ownerId.lastname : currentChat?.customerId?.firstname + " " + currentChat?.customerId.lastname}</div>
                    </div>

                </div>
                <IoIosClose onClick={() => handleCloseChat()} />
            </div>
            <div className="chat-messages">
                {messages.map((message) => {
                    return (
                        <div ref={scrollRef} key={uuidv4()}>
                            <div
                                className={`message ${message.fromSelf ? "sended" : "recieved"
                                    }`}
                            >
                                <div className="content">

                                    <p>{message.message}</p>
                                    <br />
                                    <span style={{ position: "absolute", bottom: 0, right: "5px" }}>{message.hour}:{message.mins} </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <ChatBoxInput handleSendMsg={handleSendMsg} />
        </Container>


    );
}




const Container = styled.div`
            position: fixed;
            top: 0;
            height: 400px;
            right: 0;
            bottom: 0%;
            transform: translateY(50%);
            width: 400px; /* Ajustez la largeur selon vos besoins */
            display: flex;
            flex-direction: column;
            border: 1px solid #ccc;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            overflow: hidden;
  font-family: 'Arial', sans-serif;
            @media screen and (min-width: 720px) and (max-width: 1080px) {
                /* grid-template-rows: 15% 70% 15%;                    
                height: 70%;
                width: 80%;
                position: fixed;
                bottom: 0px;
                right: 0px;
                border-radius: 5px;
                z-index: 4000;
                background-color: #fff;
                gap: 0.1rem;
                overflow: hidden;                 */
            }
            @media (max-width: 720px) {
                /* height: 100%; */
                width: 80%;
                position: fixed;
                bottom: 0px;
                right: 0px;
                border-radius: 5px;
                z-index: 4000;
                background-color: #fff;
                gap: 0.1rem;
                overflow: hidden;
                border-radius : 0;
            }



            /* Entête du chat */
            .chat-header {
                display: flex;
                justify-content: space-between;

                align-items: center;
                padding: 0 1rem;
                background-color: #fff;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
                .user-details {
                    display: flex;
                    align-items: center;
                    /* gap: .6rem; */
                    .avatar {
                        img {
                            height: 2rem;
                        }
                    }
                    .username {
                        /* h3 {
                            color: #2e2828;
                        } */
                        div {
                            color: #2e2828;
                            font-family: "Poppins";
                            font-size: .7rem;
                        }
                        
                        h5 {
                            color: #2e2828;
                            font-size: .7rem;
                        }
                    }
                }
            }
            /*  */
            .chat-messages {
                flex-grow: 1;
                overflow-y: auto;
                padding: 10px;
                @media (max-width: 720px) {
                    /* min-height: 70vh; */
                }
                &::-webkit-scrollbar {
                    width: 0.2rem;
                    &-thumb {
                        background-color: #ffffff39;
                        width: 0.1rem;
                        border-radius: 1rem;
                    }
                }
                .message {
                    display: flex;
                    align-items: center;
                    min-width: 150px;
                    margin-bottom: 5px;
                    .content {
                        max-width: 150px;
                        min-width: 100px;
                        overflow-wrap: break-word;
                        position: relative;
                        padding: 0rem .4rem;
                        font-size: .7rem;
                        border-radius: .4rem;
                        color: #d1d1d1;
                        @media screen and (min-width: 720px) and (max-width: 1080px) {
                            max-width: 70%;
                        }
                    }
                }
                .sended {
                    justify-content: flex-end;
                    .content {
                        background-color: #4B77D421;
                    }
                }
                .recieved {
                    justify-content: flex-start;
                    .content {
                        background-color: #fff;
                    }
                }
            }`;