import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
// import swal from 'sweetalert';
import Autocomplete from "react-google-autocomplete";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Checkbox from '@mui/material/Checkbox';
import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

// import {timeOutController} from './../../utils/dateControllers'
// import Checkbox from '@mui/material/Checkbox';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import CheckoutForm from "./CheckoutForm";
import swal from "@sweetalert/with-react";
import moment from "moment";
import { fr } from "date-fns/locale";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Bookcar.css";
import {
  AC_MODEL_CAR,
  AC_ADD_TRIP,
  AC_PAYDUNYA_PAYMENT_TOKEN,
  AC_STRIPE_PAY_DATA_RESET,
} from "../../actions/searchcarsaction";
import NumberFormat from "react-number-format";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";

import Loading from "../../common/loading";
import {
  AC_VIEW_ACCOUNT,
  AC_VIEW_CUSTOMER,
} from "./../../actions/accountaction";
import { addDays } from "date-fns";
import { getFinalPrice, getTravelDay } from "../../utils/priceReducer";
// import {  } from "react-router-dom";
// var paydunya = require("paydunya");
// const now = new Date();

registerLocale("fr", fr);
setDefaultLocale("fr");

const BookCar = (props) => {
  let { carId } = useParams();
  const accountInfo = props.AccountReducer.accountInfo;
  const [state] = useState({
    isLoading: props.SearchCarsReducer.isLoading,
    history: useHistory(),
  });
  let [checkOut, setCheckOut] = useState(moment(Date.parse(localStorage.getItem('checkOut'))).toDate());
  let [checkIn, setCheckIn] = useState(moment(Date.parse(localStorage.getItem('checkIn'))).toDate());

  const getPrice = () => {
    const carInfo = props.SearchCarsReducer.modelcarList[0]
    // console.log("goOut: " +goOut)
    //Récupérer le premier prix ou le second prix
    let brutPrice = 0
    if(localStorage.getItem('goOut') === 'true' || goOut === true) {
      if(carInfo.price.secondPrice) {
        brutPrice = carInfo.price.secondPrice        
      }else {
        brutPrice = carInfo.price.price        
      }
    }else {
      brutPrice = carInfo.price.price       
    }
    
    const day = getTravelDay(checkIn, checkOut)
        
    return withDriver ? getFinalPrice(brutPrice, day) + 10000: getFinalPrice(brutPrice, day)
    // setCarPrice()

    // Déterminer le prix en fonction de la tranche courante 
  }


  
  // console.log('information sur le véhicule')
  // console.log(props.SearchCarsReducer.modelcarList)
  const [estimation, setEstimation] = useState(-1);
  
  const [withDriver, setwithDriver] = useState(false);
  const [withDelivery, setwithDelivery] = useState(false);
  
  // const [destination, setDestination] = useState('');
  const [carPrice, setCarPrice] = useState(0)
  
  const [goOut, setGoOut] = useState(false);
  
  const [brutCheckin, setbrutCheckin] = useState(moment(Date.parse(localStorage.getItem('checkIn'))).toDate());
  const [brutCheckout, setbrutCheckout] = useState(moment(Date.parse(localStorage.getItem('checkOut'))).toDate());
  
  


  const disableButton = (estimation, car) => {
    if ((estimation <= 0 || !estimation)
      || (car?.requirements?.mobilityArea
        && (goOut === null))) {
      return true
    } else {
      return false
    }
  }

  const getUnitPrice =  (car) => {
    let finalPrice = 0
    if (!withDriver) {
      if (goOut && carData[0].price.secondPrice) {
        finalPrice = carData[0].price.secondPrice
      }
      else {
        finalPrice = carData[0].price.price
      }

    }
    else {
      if (goOut && carData[0].price.secondPrice) {
        finalPrice = carData[0].price.secondPrice + 10000
      } else {
        finalPrice = carData[0].price.price + 10000
      }
    }

    return finalPrice;
  }
  
  const estimatedPrices = (startLocation, endLocation) => {
    if (startLocation && endLocation) {

      let startDate = moment(startLocation, "DD-MM-YYYY HH:mm");
      let endDate = moment(endLocation, "DD-MM-YYYY HH:mm");

      // endDate.diff(startDate, 'day')

      let days = endDate.diff(startDate, "hours");

      let travelDay = Math.round((days + Number.EPSILON) * 100) / 100;
      // console.log(travelDay)
      travelDay = Math.ceil(travelDay / 24)

      if (travelDay < 0) {
        travelDay = 0
      }
      if (travelDay < 1 && travelDay > 0) {
        travelDay = 1
      }

      // const { modelcarList: carData } = props.SearchCarsReducer;
      let price = carPrice;
      // const destination = localStorage.getItem('destination')
      // let newEstimatedPrice = 0
      // if (goOut && carData[0]?.price?.secondPrice) {
      //   if (with_driver) {
      //     price = price + carData[0]?.price?.secondPrice + 10000;
      //   } else {
      //     price = carData[0]?.price?.secondPrice;
      //   }
      // } else {
      //   if (with_driver) {
      //     price = price + carData[0].price.price + 10000;
      //   } else {
      //     price = carData[0].price.price;
      //   }
      // }
      
      // console.log('Prix unitaire: ' + price)
      let newEstimatedPrice = price * travelDay;
      if(withDriver && withDelivery)
        newEstimatedPrice = newEstimatedPrice + 10000
      
      return newEstimatedPrice.toFixed(0)
      // return 3000
    }
  };

  const incrementTotalPriceForDelivery = (withDelivery) => {
    if(withDelivery === true){
      const globalPrice = parseInt(estimation) + 10000      
      setEstimation(globalPrice)
      
    }else {
      // const globalPrice = carPrice
      const globalPrice = parseInt(estimation) - 10000 
      setEstimation(globalPrice)
    }
  }

  const incrementPriceWithDriver = (withDriver) => {
    if(withDriver === true){
      const globalPrice = carPrice + 10000      
      setCarPrice(globalPrice)
      
    }else {
      // const globalPrice = carPrice
      const globalPrice = carPrice - 10000 
      setCarPrice(globalPrice)
    }
  }

  useEffect(() => {
    incrementTotalPriceForDelivery(withDelivery)
  }, [withDelivery])

  useEffect(() => {
    incrementPriceWithDriver(withDriver) 
    
  }, [withDriver])


  useEffect(() => {    
    const treatedPrice = getPrice()
    setCarPrice(treatedPrice)
      // console.log(carPrice)
    // if(withDriver) setCarPrice(carPrice + 10000)
    
  }, [checkIn, checkOut, goOut])

  
  useEffect(() => {
    const userid = localStorage.getItem("siteUser");
    props.ViewAccount(userid);
    props.modelCar({ id: carId });
    // const carData = props.SearchCarsReducer.modelcarList;
    // console.log(carData)
    // setbrutCheckin(addHours(12))
    // setbrutCheckout(addHours(14))
    // const { modelcarList: carData } = props.SearchCarsReducer;

    // if(props.SearchCarsReducer?.modelcarList){
    //   const carData = props.SearchCarsReducer.modelcarList
    //   props.ViewCustomer(carData[0].customerId)
    // }

    // props.stripeDataReset({});
    
    // if (localStorage.getItem('destination')) {
    //   setDestination(localStorage.getItem('destination'));
    // }
    if (localStorage.getItem('goOut')) {
      if(localStorage.getItem('goOut') === 'true')
        setGoOut(true);
        
      else
        setGoOut(false);
    }
    

    const estimate = estimatedPrices(checkIn, checkOut);
    setEstimation(estimate);

  }, [checkIn, checkOut, goOut, carPrice]);

  const maxDateController = (checkin, nbDays) => {
    let finalDate = moment(checkin, "DD-MM-YYYY HH:mm").add(nbDays, 'days').toDate();
    return finalDate;
  }

  const handleChangeCheckin = (date) => {
    setbrutCheckin(date)
    setCheckIn(moment(date).format("DD-MM-YYYY HH:mm"))
  }
  const handleChangeCheckout = (date) => {
    setbrutCheckout(date);
    // Definition de la date formaté
    setCheckOut(moment(date).format("DD-MM-YYYY HH:mm"))
  }

  // function addHours(numOfHours, date = new Date()) {
  //   date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  //   return date;

  //   // 👇️ Add 1 hour to current date
  //   // const result = addHours(1);

  //   // 👇️ Add 2 hours to another date
  //   // const date = new Date('2022-03-14T09:25:30.820');

  //   // 👇️ Mon Mar 14 2022 11:25:30
  //   // console.log(addHours(2, date));
  // }
  // const onSelectDestination = (place) => {
  //   setDestination(place.formatted_address)
  //   localStorage.setItem('destination', place.formatted_address)
  // }

  // const checkMinTime(date){

  // }

  // const editDestination = () => {

  //   swal({
  //     heightAuto: false,
  //     buttons: {
  //       cancel: {
  //         text: "Annuler",
  //         value: null,
  //         visible: true,
  //         className: "",
  //         closeModal: true,
  //       },
  //       confirm: {
  //         text: "Enregistrer",
  //         value: true,
  //         visible: true,
  //         className: "",
  //         closeModal: false
  //       }
  //     },
  //     content: (
  //       <div>
  //         <h4>Où allez-vous ?</h4>
  //         <div className="choose-destination">
  //           <Autocomplete
  //             style={{ border: "none", borderRadius: "25px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
  //             className="form-control"
  //             placeholder={'Ville de destination - Ex: Bouaké'}
  //             onChange={(event) => this.onChangeDestination(event)}
  //             onPlaceSelected={(place) => {
  //               onSelectDestination(place)
  //               // console.log(place)
  //               // if (place?.formatted_address) {
  //               //   this.onChangePlaceValue(place);                        
  //               //   this.onSelectDestination(place?.formatted_address)                        
  //               //   console.log(place?.formatted_address)
  //               // }
  //               // else {
  //               //   this.setState({ addresserr: true, loading: false })
  //               // }

  //             }}
  //             value={destination}
  //             types={['locality']}
  //             componentRestrictions={{ country: "civ" }}
  //           />
  //         </div>
  //       </div>
  //     )
  //   }).then((value) => {
  //     if (value && localStorage.getItem('destination')) {
  //       swal("Parfait", "Adresse enregistrée avec succès!", "success", { buttons: false, timer: 2000 });
  //     }
  //     else if ((localStorage.getItem('destination') === '') && value) {
  //       swal("Hummm", "Merci de renseigner l'adresse de destination!", "warning", { buttons: { confirm: { text: "Bien reçu", }, cancel: { visible: false } }, timer: 10000 });
  //     }
  //     else {
  //       swal.close()
  //     }

  //   })
  // }

  const applyPreparingTime = () => {
    var today = new Date();
    var tomorrow = new Date();
    return tomorrow.setHours(today.getHours() + 4);
  }

  // const checkTimeOut = (endDate) => {
  //   let endLocation = moment(endDate, "DD-MM-YYYY HH:mm");
  //   let startDate = new Date()
  //   let startLocation = moment(startDate, "DD-MM-YYYY HH:mm");

  //   // endDate.diff(startDate, 'day')

  //   let hours = endLocation.diff(startLocation, "hours");
  //   if(hours > 0)
  //     return endDate;

  //   return startDate
  // }

  // const handleCheckoutMinDate = () => {

  // }

  const checkPreparingTime = (endDate) => {
    // alert(checkPreparingTime(checkIn))
    let endLocation = moment(endDate, "DD-MM-YYYY HH:mm");
    let startDate = new Date()
    let startLocation = moment(startDate, "DD-MM-YYYY HH:mm");

    // endDate.diff(startDate, 'day')

    let hours = endLocation.diff(startLocation, "hours");

    // let travelDay = Math.round((days + Number.EPSILON) * 100) / 100;
    // console.log(hours)
    // travelDay = travelDay / 24

    if (hours >= 3) {
      return false
    }
    return true
  }

  // let handleColor = (time) => {
  //   return time.getHours() > 12 ? "text-success" : "text-error";
  // };

  // const checkWithDriver = (isChecked) => {    
  //   setwithDriver(isChecked)     
  //   let globalPrice = carPrice
  //   if(isChecked) {
  //     console.log('On fait ' + globalPrice + '+ 10000')
  //     globalPrice = globalPrice + 10000
  //     console.log(globalPrice)
  //     setCarPrice(globalPrice)
  //   }
      
  //   else {
  //     console.log('On fait ' + globalPrice + ' - 10000')
  //     globalPrice = globalPrice - 10000
  //     console.log(globalPrice)
  //     setCarPrice(globalPrice)
  //   }
      

  //   console.log('after set: ' + carPrice)
  // }

  const booking = () => {
    if (checkIn instanceof Date) {
      checkIn = moment(checkIn).format("DD-MM-YYYY HH:mm")
    }

    if (checkOut instanceof Date) {
      checkOut = moment(checkOut).format("DD-MM-YYYY HH:mm")
    }


    const carData = props.SearchCarsReducer.modelcarList;
    // const accountInfo = props.AccountReducer.accountInfo;
    const userId = localStorage.getItem("siteUser");
    if (userId) {
      // Récupérer les informations sur le client
      // Récupérer les informations sur la voiture Ok


      // verification du compte

      if (checkPreparingTime(checkIn)) {
        swal({
          title: "Temps de préparation trop court !",
          // text: "Il faut que ",
          buttons: "D'accord",
          dangerMode: true,
        })
      }
      else {
        if (carData.length) {
          let formData = {
            customerId: userId,
            customerFullName: accountInfo?.firstname + " " + accountInfo?.lastname,
            customerNumber: accountInfo?.mobilenumber,
            ownerNumber: carData[0]?.userId?.mobilenumber,
            imageProfile: accountInfo?.image,
            ownerId: carData[0]?.userId?._id,
            carId: carData[0]?._id,
            carName:
              carData[0]?.carmodel?.make?.brandname +
              " " +
              carData[0]?.carmodel?.model?.name,
            tripStatus: carData[0]?.snapshot === true ? 3 : 1,
            checkin: checkIn,
            checkout: checkOut,
            withDriver: withDriver,
            withDelivery: withDelivery,
            price: estimation,
            goOut: goOut,
          };
          // console.log(carData[0])
          // Si le véhicule est autorisé à sortir de la ville
          if (carData[0].requirements.mobilityArea) {
            formData['goOut'] = goOut
          }
          // Ajouter une commande de véhicule
          // console.log(formData)
          props.addTrip(formData).then(() => state.history.push("/rental"));

        }

      }
    } else {
      localStorage.setItem("redirect", window.location.pathname);
      state.history.push("/sign-in");
    }
  };
  // const destination = localStorage.getItem("destination") ?? ""


  const { isLoading, modelcarList: carData } = props.SearchCarsReducer;
  const driver = carData[0]?.requirements?.withDriver;
  const driverBool = driver === undefined || driver === false ? false : true;


  return (
    <div className="bg-container mt-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="list-car-card border-0" style={{ marginBottom: "100px" }}>
              {isLoading ? (
                <Loading />
              ) : (
                <div className="row">
                  {/* <div className="col-lg-5">
                    <div className="dash-img">
                      <img
                        src={URL.IMAGEURL + carData[0]?.carimage?.mainimage}
                        alt=""
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-12">
                    <div className="dash-img">
                      <img
                        src={URL.IMAGEURL + carData[0]?.carimage?.mainimage}
                        alt="Principal"
                      />
                    </div>
                    <div className="dash-content">
                      <div className="text-center text-white" style={{ backgroundColor: "#004AAD", borderRadius: "3px" }}>
                        <div className="car-title px-3 py-3">
                          {carData[0]?.carmodel?.make?.brandname} {carData[0]?.carmodel?.model?.name}
                        </div>
                        {/* <div className="rent">
                          
                        </div> */}
                      </div>

                      <div className="listing-card ">
                        <div className="listing-title">
                          {/* {accountInfo?.verification !== 1 && <div className="alert alert-danger">Votre compte n'a pas </div>} */}
                          <div style={{ backgroundColor: "#fcfcfc" }}>
                            <h2 className="px-3 py-3" style={{ fontSize: "1.5rem" }}>Estimons votre demande</h2>
                            <div className="alert alert-info" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="mr-2"><i className="fas fa-info-circle"></i></div>
                              <div>Merci de considérer le temps de préparation estimé à <strong>3H</strong> du véhicule que vous souhaitez louer</div>
                            </div>
                          </div>

                          {isLoading ? (
                            <Loading />
                          ) : (
                            <div className="row">
                              <div className="container-fluid p-0">
                                <div
                                  className="containerTripsDate"
                                  style={{

                                  }}
                                >
                                  {/* <div> */}
                                  {/* <label>Date de début : </label> */}
                                  <div className="mb-3 marginDate" style={{ flex: 1, position: 'relative' }}>
                                    <DatePicker
                                      // minTime={new Date(0, 0, 0, new Date().getHours(), new Date().getMinutes())}
                                      // maxTime={new Date(0, 0, 0, 23)}
                                      locale="fr"
                                      timeFormat="HH:mm"
                                      className='form-control pt-5 pb-4 px-3'
                                      dateFormat="dd-MMM-yyyy HH:mm"
                                      timeInputLabel="Heure:"
                                      selected={brutCheckin}
                                      onChange={(date) =>
                                        handleChangeCheckin(date)
                                      }
                                      // value={}
                                      showTimeInput
                                      showTimeSelect
                                      // autoFocus={true}
                                      // allowSameDay={true}
                                      // children={<h2>Bonjour</h2>}
                                      // excludeScrollbar={true}
                                      title="Date de debut"
                                      // minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes())}
                                      minDate={applyPreparingTime()}
                                      timeCaption="Temps"
                                      placeholderText="Début de location"

                                    />
                                    <span className="datePickerlabelBookCar">Début location</span>
                                  </div>

                                  {/* </div> */}

                                  {/* <div> */}
                                  {/* <label>Date de fin : </label> */}

                                  <div className="mb-3 fullWidth" style={{ flex: 1, position: 'relative' }}>
                                    <DatePicker
                                      locale="fr"
                                      className="form-control pt-5 pb-4 px-3"
                                      dateFormat="dd-MMM-yyyy HH:mm"
                                      timeInputLabel="Heure:"
                                      selected={brutCheckout}
                                      onChange={(date) => {
                                        handleChangeCheckout(date)
                                      }}
                                      showTimeInput
                                      showTimeSelect
                                      minDate={(brutCheckin) ? brutCheckin : new Date() + 1}
                                      maxDate={maxDateController(brutCheckin, 21)}
                                      timeCaption='Temps'
                                      placeholderText='Fin de location'
                                    />
                                    <span className="datePickerlabelBookCar">Fin location</span>
                                  </div>
                                </div>
                              </div>
                              <div className="container-fluid">
                                <div className="flex-list" style={{ display: 'flex', flex: 1 }}>
                                  <div className="list-content" style={{ textTransform: 'uppercase', display: 'flex', flex: 1, justifyContent: "space-between", alignItems: 'center', flexDirection: 'column' }}>
                                    <h3 className="">Prix Journalier</h3>
                                    <p className="m-0" style={{ fontSize: "2rem" }}>
                                      {!withDriver && (
                                        <NumberFormat
                                          value={carPrice}
                                          className="foo"
                                          displayType={"text"}
                                          thousandSeparator={" "}
                                        />
                                      )}
                                      {withDriver && (
                                        <NumberFormat
                                          value={carPrice}
                                          className="foo"
                                          displayType={"text"}
                                          thousandSeparator={" "}
                                        />
                                      )}{" "}
                                      FCFA
                                    </p>
                                  </div>


                                  <hr />
                                </div>
                              </div>
                              <div className="container-fluid">
                                <h5 className="titleSup card-header" style={{ backgroundColor: 'rgb(247 247 247)', color: "#373232", border: "1px dashed rgb(0, 74, 173)", }}>Options Location</h5>
                                <div className="flex-list pl-2" style={{ backgroundColor: "#fcfcfc" }}
                                >
                                  <label htmlFor="withdriver"
                                    className={`${driverBool && "text-secondary"} `}
                                    style={{ flex: 1, cursor: 'pointer' }}>
                                    <strong>Avec chauffeur</strong>
                                    <br /> {!driverBool
                                      ? "10 000 FCFA"
                                      : <strike>10000 FCFA</strike>}
                                  </label>

                                  <div className="">

                                    <Checkbox id="withdriver"
                                      disabled={driverBool}
                                      checked={withDriver}
                                      onChange={(event) => { 
                                        // console.log(event.target.checked)
                                        setwithDriver(event.target.checked)                                                                                                                                                                   
                                        // checkWithDriver(event.target.checked)
                                        }} />
                                  </div>

                                </div>
                                <div className="flex-list pl-2"
                                >
                                  <label htmlFor="withdelivery" style={{ flex: 1, cursor: 'pointer' }}><strong>Livraison du véhicule à domicile</strong> <br />10 000 FCFA </label>
                                  <div>
                                    <Checkbox id="withdelivery" checked={withDelivery} 
                                    onChange={(event) => {
                                    setwithDelivery(event.target.checked)
                                  }} />
                                  </div>
                                </div>

                                {carData[0]?.requirements?.mobilityArea &&
                                  <div className="flex-list pl-2"
                                  >
                                    <label htmlFor="goOut" style={{ flex: 1, cursor: 'pointer' }}><strong>Sortir de la ville de stationnement</strong> <br /> {goOut ? 'Oui' : "Non"} </label>
                                    <div>
                                    <Checkbox id="goOut" checked={goOut} 
                                    onChange={(event) => {
                                      // console.log(event.target.checked)
                                      // console.log('LocalStorage goOut: ' + localStorage.getItem('goOut'))
                                      setGoOut(event.target.checked)
                                      localStorage.setItem('goOut', event.target.checked)
                                  }} />
                                  </div>
                                    {/* <div>
                                      <button id="destination" className="correctDestinationButton" onClick={() => editDestination()}>{(destination) ? "Corriger" : "Ajouter"}</button></div> */}
                                  </div>
                                  }
                              </div>
                              <div className="container-fluid row justify-content-between mx-1">
                                <div
                                  className="flex-list pl-2"
                                  style={{ backgroundColor: "#fcfcfc", flex: 1 }}
                                >
                                  <div className="list-content" style={{ flex: 1, textAlign: 'center', }}>
                                    <h3 style={{ textTransform: 'uppercase' }}>Prix total estimé </h3>
                                    <p className="m-0">
                                      <NumberFormat
                                        value={estimation > 0 ? estimation : 0}
                                        className="foo"
                                        style={{ fontSize: "2rem" }}
                                        displayType={"text"}
                                        thousandSeparator={" "}
                                      />{" "}
                                      FCFA
                                    </p>
                                  </div>
                                </div>
                                {/* 
                                <div
                                  className="flex-list"
                                  style={{ border: "none" }}
                                >
                                  <div className="list-content">
                                    <textarea
                                      className="form-control"
                                      type={"text"}
                                      placeholder="Motif de la location"
                                      rows={3}
                                    ></textarea>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="container-fluid">
                          <button
                            className="btn px-3 py-3 my-3 text-white"
                            onClick={() => booking()}

                            disabled={disableButton(estimation, carData[0])}
                            style={{
                              color: "white",
                              backgroundColor: "#004AAD",
                              width: "100%",
                              fontWeight: 'bold'
                            }}
                          >
                            LOUER
                          </button>{" "}

                        </div>
                        {/* <Widget /> */}
                      </div>

                      {/* <hr className="seperator" /> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    SearchCarsReducer: state.SearchCarsReducer,
    AccountReducer: state.AccountReducer,
  };
}

// On injecte ici toutes les actions
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      modelCar: AC_MODEL_CAR, //
      addTrip: AC_ADD_TRIP, // Ajouter une nouvelle location
      ViewAccount: AC_VIEW_ACCOUNT,
      ViewCustomer: AC_VIEW_CUSTOMER,
      setPaydunyaToken: AC_PAYDUNYA_PAYMENT_TOKEN,
      stripeDataReset: AC_STRIPE_PAY_DATA_RESET,
    },
    dispatch
  );
}

const BookCarComponent = connect(mapStateToProps, mapDispatchToProps)(BookCar);

export default BookCarComponent;
