import React from "react";
import { /*Link,*/ Redirect } from "react-router-dom";

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
    };
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }

  validateForm() {
    this.setState({ next: true });
  }

  previous() {
    this.setState({ previous: true });
  }
  render() {
    const next = this.state.next;
    const previous = this.state.previous;
    if (previous) {
      return <Redirect to="/add-vehicle" />;
    }
    if (next) {
      return <Redirect to="/ready-to-rent" />;
    }
    return (
      <section>
        <div className="form-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="list-my-car">
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "85%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                    <h5>List My Car</h5>
                    <div className="title-card">You’re almost done!</div>
                    <div className="form9-img d-flex">
                      <img
                        src="https://res.cloudinary.com/monbolide/image/upload/v1643000941/ProjectImage/images/form-img9_xvi81e.png"
                        alt=""
                      />
                      <p className="m-0">
                        Our Monbolide Connect team will call you to walk you
                        through the next steps. It won't take more than{" "}
                        <b>10 min</b>
                      </p>
                    </div>
                    <a href="" className="Woohoo-btn d-block mt-4">
                      Schedule an appointment
                    </a>
                    <hr className="mt-4 mb-4" />
                    <div className="list-info">
                      135 owners registered their car today
                    </div>
                  </div>
                  <div className="list-bottom">
                    <div className="btn-nav">
                      <button
                        className="prev-button mr-2"
                        onClick={this.previous}
                      >
                        <span className="cobalt-Icon">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                          </svg>
                        </span>
                        Previous
                      </button>
                      <button
                        className="Woohoo-btn"
                        onClick={this.validateForm}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Schedule;
