import React from "react";
import { CodeValidate } from "../../common/validate";
import {
  AC_OTP_VERIFICATION, AC_SEND_OTP_CODE
} from "../../actions/accountaction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { Redirect } from "react-router-dom";
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";
import { Error } from "../../common/swal";

const recaptchaRef = React.createRef();
class OtpValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signup: false,
      signin: true,
      signupEmail: true,
      code: "",
      isPending: false,
      codeError: false,
    };
    this.optValidateForm = this.optValidateForm.bind(this);
  }

  onchangeValue(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "code") {
      this.setState({
        code: value,
        codeError: false
      })
    }
  }

  async optValidateForm(e) {
    // e.preventDefault();

    this.setState({ isPending: true })
    const code = this.state.code;
    const codeError = this.state.codeError;
    const valideCodeCheck = CodeValidate(code);

    if (code) {
      if (valideCodeCheck) {
        this.setState({ codeError: false });
      } else
        this.setState({ codeError: true });
    } else {
      this.setState({ codeError: true });
    }
    //Sign in
    if (code && !codeError) {
      const token = await recaptchaRef.current.executeAsync();
      const formData = {
        code: code,
        token: token,
      };
      // console.log(formData);
      this.props.OtpVerification(formData).then(() => {
        this.setState({ isPending: false })
      });
      recaptchaRef.current.reset();
    } else {
      Error('Merci de renseigner le code de validation!')
      this.setState({ isPending: false })
    }
  }

  sendOptCode = async () => {
    this.setState({ isPending: true })
    if (localStorage.getItem('number')) {
      // const number = localStorage.getItem('number')
      // const formData = {
      //   number: number
      // }
      const token = await recaptchaRef.current.executeAsync();
      const formData = {token : token}
      this.props.SendOptCode(formData).then(() => {
        this.setState({ isPending: false })
        recaptchaRef.current.reset();
      })
      
    }
    else {
      swal({
        title: "Requête impossible",
        text: "Merci de soumettre avant le formulaire d'inscription",
        buttons: "D'accord",
        dangerMode: true,
      })
      this.setState({ isPending: false })

    }


  }

  navigate(e) {
    const path = e.target.id;
    const name = e.target.name;
    if (path === "pills-home-tab") {
      this.setState({ signin: true, signup: false });
    }
    if (path === "pills-profile-tab") {
      this.setState({ signin: false, signup: true });
    }
    if (name === "signin") {
      this.setState({ signin: true, signup: false });
    }
    if (name === "signup") {
      this.setState({ signin: false, signup: true });
    }
    if (name === "emailSignup") {
      this.setState({ signupEmail: false });
    }
  }
  render() {
    return (
      <div className="main-div">
        <div className="row ml-0 mr-0">
          <div className="col-lg-6 p-0 m-0">
            <div className="main-layout" style={{ display: 'flex', height: "100%" }}>
              <div className="tab-content px-3 py-3" id="pills-tabContent" style={{ position: 'relative', top: '-80px', backgroundColor: 'white', borderRadius: '10px', boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", margin: 'auto' }}>
                <div

                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="signupTitle">vérification</div>
                  <p
                    className="pb-1"
                    style={{ textAlign: "center", fontSize: "13px" }}
                  >
                    Merci de renseigner le code d'activation que nous avons
                    envoyé à votre numéro !
                  </p>
                  <form action="#" onSubmit={this.optValidateForm}>
                    <div className="container p-0">                      
                      <input
                        type="text"
                        placeholder="Ex: 0707"
                        className="form-control form-pad"
                        name="code"
                        value={this.state.code}
                        onChange={(e) => this.onchangeValue(e)}
                      />
                     

                      <div className="otp-confirm-container py-3">
                        <button type="button" className="btn otp-confirm-btn" disabled={this.state.isPending} onClick={this.optValidateForm} >Confirmer</button>
                        <button type="button" className="btn btn-outline-black otp-alt-btn" style={{ textDecoration: 'none' }} disabled={this.state.isPending} onClick={() => this.sendOptCode()}><span>Demander un nouveau code</span> </button>

                        {/* <input
                            type="submit"
                            value="Demander un nouveau code"
                            className="form-control btn "                            
                            onClick={this.optValidateForm}
                          /> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="signin-img">
              {/* <img src="/assets/images/signin_banner.jpg" alt="" /> */}
            </div>
          </div>
        </div>
        <ReCAPTCHA
                        className="recaptchaClass"
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6Ld3nmklAAAAANIX-BuKyGhpGaKs0x1SLPzu994j"

                      />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      OtpVerification: AC_OTP_VERIFICATION,
      SendOptCode: AC_SEND_OTP_CODE
    },
    dispatch
  );
}

const OtpValidationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(OtpValidation);

export default OtpValidationComponent;
