/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import BounceLoader from "react-spinners/BounceLoader";
import swal from "@sweetalert/with-react";
import { Slider } from 'antd';
import formatDistance from '../../utils/functions'
import { InputNumber } from 'antd';
// import FormLabel from '@mui/material/FormLabel';
import { css } from "@emotion/react";

// import moment from "moment";
// import { fr } from "date-fns/locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../dashboard/BannerSearch.css";
// import ReactStars from "react-rating-stars-component";
import "./carlist.css";
import { getCityFromPlace } from "../../utils/geoLocation";
import {
  AC_SEARCH_CAR,
  AC_PRICE_FILTER,
  AC_MODEL_CAR,
  AC_ADD_TRIP,
} from "../../actions/searchcarsaction";
import moment from "moment";
// import { position } from "dom-helpers";
import Map from "./map";
import { auto } from "@cloudinary/url-gen/qualifiers/quality";
import { copyFiveElements } from "../../utils/functions";
import ThemeStyle from "../../utils/ThemeStyle";

const override = css`
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
class Carlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRadio: null,
      range: [this.props.SearchCarsReducer?.pricerange?.minprice, this.props.SearchCarsReducer?.pricerange?.maxprice],
      userRangePrice: [],
      priceRangeIsDisabled: false,
      withDriver: true,
      mapview: false,
      sortChoiced: null,
      filters: true,
      models: false,
      showmore: true,
      carlist: true,
      transmission: "",
      seat: "",
      year: "",
      price: [],
      address: localStorage.getItem("place") || "",
      parking: "",
      equipment: [],
      viewdetail: false,
      carId: "",
      activeFilter: false,
      width: null,
      height: null,
      isSmartphone: window.innerWidth <= 992 ? true : false,
      place: localStorage.getItem("place").split("/")[0] || "",
      checkIn: "",
      checkOut: "",
      brutCheckin: moment(Date.parse(localStorage.getItem('checkIn'))).toDate(),
      brutCheckout: moment(Date.parse(localStorage.getItem('checkOut'))).toDate(),
      addresserr: false,
      loading: false,
      destination: "",
      goOutCityState: false,
      currentCar: {},
      allBrands: null,
      filteredBrands: [],
      brand: '',
      hasSelectedBrand: false,
      coordinate: {
        lat: null,
        lng: null
      }
    };
  }

  getBrandsId() {
    const brandsSelected = this.state.filteredBrands.filter(item => item.selected === true);
    const brandsId = brandsSelected.map(item => item._id);
    return brandsId
  }

  componentDidMount() {
    // this.setState({brutCheckin: Date.parse(localStorage.getItem('checkIn')), brutCheckout: Date.parse(localStorage.getItem('checkOut'))})        
    this.setState({ loading: true });
    const address = localStorage.getItem("place");
    const id = localStorage.getItem("siteUser");
    let withDriver = this.state.withDriver


    window.addEventListener("resize", this.handleResize);
    const departure = localStorage.getItem('userCity')
    if (address) {
      const value = address.split("/");
      // this.setState({})
      this.setState(prevState => ({
        coordinate: {
          ...prevState.coordinate,
          lat: parseFloat(value[1]),
          lng: parseFloat(value[2]),
        },
      }));

      this.props
        .searchCar({
          lat: parseFloat(value[1]),
          lng: parseFloat(value[2]),
          id,
          checkIn: localStorage.getItem("checkIn"),
          checkOut: localStorage.getItem("checkOut"),
          departure: departure,
          goOutCityState: this.state.goOutCityState,
          withDriver: withDriver
        })
        .then(() => this.setState({ loading: false }));
      this.setState({ place: value[0] });
    }

    // Récupérer toutes les marques
    this.setState({ allBrands: this.props.DashboardReducer.brandList })
    // if(this.state.allBrands.length){

    this.setState({ filteredBrands: copyFiveElements(this.props.DashboardReducer.brandList) })



  }
  componentDidUpdate(prevProps, prevState) {
    // Cet effet sera exécuté après chaque mise à jour du composant    

    // Vous pouvez accéder aux propriétés précédentes (prevProps) et à l'état précédent (prevState)
    // pour effectuer certaines actions basées sur les changements de ces valeurs.
  }

  checkIfBrandHasSelected() {
    this.setState(prevState => ({
      hasSelectedBrand: prevState.filteredBrands.some(item => item.selected === true),
    }));
    // this.setState({hasSelectedBrand: this.state.filteredBrands.some(item => item.selected === true)});
  }
  initializeBrands = () => {
    this.setState(prevState => ({
      filteredBrands: prevState.filteredBrands.map(brand => {
        if (brand.selected === true) {
          return { ...brand, selected: false };
        }
        return brand;
      })
      , hasSelectedBrand: false
    }));

  }



  maxDateController = (checkin, nbDays) => {
    let finalDate = moment(checkin, "DD-MM-YYYY HH:mm").add(nbDays, 'days').toDate();
    return finalDate;
  }



  handleChangeCheckin = (date) => {
    localStorage.setItem("checkIn", date);
    this.setState({
      brutCheckin: date,
      checkIn: moment(date).format("DD-MM-YYYY HH:mm"),
    });
  };

  handleChangeCheckout = (date) => {
    localStorage.setItem("checkOut", date);
    this.setState({
      brutCheckout: date,
      checkOut: moment(date).format("DD-MM-YYYY HH:mm"),
    });
  };

  handleChangegoOutCity = (event) => {
    this.setState({ goOutCityState: event.target.checked, loading: true })

    const seat = this.state.seat;
    const array = this.state.equipment;
    const year = this.state.year;
    const parking = this.state.parking;
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const sortChoiced = this.state.sortChoiced;
    const withDriver = this.state.withDriver;
    const goOutCityState = event.target.checked;
    const departure = localStorage.getItem('userCity');
    const type = this.state.isRadio;
    this.props
      .searchCar({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: this.state.transmission,
        seat: seat,
        year: year,
        type: type,
        parking: parking,
        sortChoiced: sortChoiced,
        withDriver: withDriver,
        departure: departure,
        goOutCityState: goOutCityState,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId()
      }).then(() => this.setState({ loading: false }))
  }

  onSetDestination = (place) => {
    this.setState({ destination: place.formatted_address, loading: true })
    // Lancement de la requête
    const seat = this.state.seat;
    const array = this.state.equipment;
    const year = this.state.year;
    const parking = this.state.parking;
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const sortChoiced = this.state.sortChoiced;
    const withDriver = this.state.withDriver;
    const city = getCityFromPlace(place)
    const type = this.state.isRadio
    const departure = localStorage.getItem('userCity')

    this.props
      .searchCar({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: this.state.transmission,
        seat: seat,
        year: year,
        type: type,
        parking: parking,
        sortChoiced: sortChoiced,
        withDriver: withDriver,
        destination: city,
        departure: departure,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId()
      })
      .then(() => this.setState({ loading: false }));
  }
  onChangeDestinationValue = (event) => {
    this.setState({ destination: event.target.value })


  }

  onSetPlace = (event) => {
    this.setState({ place: event.target.value });
    this.setState({ address: event.target.value });
  };
  onChangePlaceValue(place) {
    // const name = "address";
    // const value = event;        
    const defaultLocation = "Abidjan, Côte d'Ivoire/5.3599517/-4.0082563";
    

    const address = place.formatted_address ? place.geometry.location : null;
    
    // console.log(place)
    // console.log("address: " + address);
    // const setaddress = (event.formatted_address) ? event.formatted_address + "/" + event.geometry.location.lat() + "/" + event.geometry.location.lng() : defaultLocation;

    localStorage.setItem("userCity", getCityFromPlace(place));

    this.setState(prevState => ({
      coordinate: {
        ...prevState.coordinate,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    }));
    const setplace = place?.formatted_address
      ? place.formatted_address +
      "/" +
      place.geometry.location.lat() +
      "/" +
      place.geometry.location.lng() : defaultLocation

    localStorage.setItem("place", setplace);
    // localStorage.removeItem("time");    
    if (place?.formatted_address) {
      this.setState({
        address: address,
        place: place?.formatted_address,
        addresserr: false,
      });
    } else {
      this.setState({
        addresserr: true,
        loading: false
      });
    }

  }

  // Gérer les type de location (Avec ou sans chaffeur)
  handleChangeTypeTrips = (event) => {
    // La value est un string par défaut
    // Une conversion est nécessaire    
    const value = (event.target.value) === 'true' ? true : false;

    this.setState({ withDriver: value, loading: true });
    const seat = this.state.seat;
    const array = this.state.equipment;
    const year = this.state.year;
    const type = this.state.isRadio;
    const parking = this.state.parking;
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const sortChoiced = this.state.sortChoiced;
    const destination = this.state.destination;
    const departure = localStorage.getItem('userCity')

    // const sortChoiced = this.state.sortChoiced
    this.props
      .searchCar({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: this.state.transmission,
        seat: seat,
        year: year,
        type: type,
        parking: parking,
        sortChoiced: sortChoiced,
        withDriver: value,
        destination: destination,
        departure: departure,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId()
      })
      .then(() => this.setState({ loading: false }));
  }

  // Tirer les location par (plus recente, moins recentes ...)
  handleChangeSortBy = (event) => {
    // this.setState({sortChoiced: event.target.value});
    const value = parseInt(event.target.value);
    this.setState({ sortChoiced: parseInt(event.target.value), loading: true });
    // this.setState({ range:  });
    const seat = this.state.seat;
    const type = this.state.isRadio;
    const array = this.state.equipment;
    const year = this.state.year;
    const parking = this.state.parking;
    const withDriver = this.state.withDriver
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const destination = this.state.destination;
    const departure = localStorage.getItem('userCity')
    const priceRange = this.state.userRangePrice
    this.props
      .searchCar({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: this.state.transmission,
        seat: seat,
        year: year,
        priceRange: priceRange,
        type: type,
        parking: parking,
        sortChoiced: value,
        withDriver: withDriver,
        destination: destination,
        departure: departure,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId()
      })
      .then(() => this.setState({ loading: false }));
  };

  handleResize = () => {
    if (window.innerWidth <= 768) {
      this.setState({ isSmartphone: true });
    } else {
      this.setState({ isSmartphone: false });
    }
  };

  setaddress(e) {
    this.setState({ address: e.target.value });
  }

  handleClickFilter = (event) => {
    // event.target.classList.toggle('filterContainerColor')
    this.setState({ activeFilter: !this.state.activeFilter });
  };

  viewTest() {
    this.setState({ mapview: !this.state.mapview });
  }
  showfilters() {
    this.setState({ filters: true });
  }
  onSelectDestination = (address) => {
    this.setState({ destination: address.formatted_address })
  }


  showmodels(event) {
    localStorage.setItem("modelId", event.target.id);
    let car = {}
    this.setState({ destination: '', activeFilter: false })
    this.props.modelCar({ id: event.target.id }).then(() => {
      if (
        this.props?.SearchCarsReducer?.modelcarList) {
        car = this.props?.SearchCarsReducer?.modelcarList[0];
      }
      const title = "Destination";
      const message = "Souhaiteriez-vous sortir hors de la ville avec la " + car.carmodel.make.brandname + " " + car.carmodel?.model?.name + " ?"
      // const city = localStorage.getItem('userCity')
      if (car.requirements.mobilityArea) {
        swal({
          title: title,
          text: message,
          buttons: {
            cancel: {
              text: "Fermer", value: null, visible: true, className: "", closeModal: true
            },
            withoutDestination: {
              text: "Non", value: 'withoutDestination', visible: true, className: "swal-button--confirm", closeModal: true
            },
            withDestination: {
              text: 'Oui', value: 'withDestination', visible: true, className: "swal-button--confirm", closeModal: true
            },
          }
          // ["Fermer ", "Non, juste " + city, 'Oui'],

        }).then((value) => {
          switch (value) {
            // L'utilisateur choisi de ne pas sortir de la ville
            case "withoutDestination": {
              localStorage.setItem('goOut', false)
              this.setState({
                ...this.state,
                viewdetail: true,
                carId: event.target.id,
              });
              break;
            }

            // L'utilisateur choisi de sortir de la ville
            case "withDestination": {
              localStorage.setItem('goOut', true)
              swal.close()
              this.setState({
                ...this.state,
                viewdetail: true,
                carId: event.target.id,
              });
              break;
            }
            default: {
              localStorage.removeItem('goOut')
              swal.close()
            }
          }
        })
      } else {
        swal({
          title: "Information",
          text: "Le propriétaire n'autorise pas une sortie hors de l'adresse saisie. Vous pouvez choisir un autre véhicule ou décider de continuer.",
          buttons: {
            cancel: {
              visible: true,
              text: "Annuler"
            },
            confirm: {
              visible: true,
              text: "Continuer"
            }
          },
        })
          .then((willDelete) => {
            if (willDelete) {
              this.setState({
                ...this.state,
                viewdetail: true,
                carId: event.target.id,
              });
            } else {
              swal.close()
            }
          });
      }

    });

    // if (
    //   this.props?.SearchCarsReducer?.modelcarList) {
    //   car = this.props?.SearchCarsReducer?.modelcarList[0];
    // }
    // Afficher un modal demandant à l'utilisateur s'il souhaiterait sortir de la ville qu'il a choisi
    // Si ou demander la destination et definir la destination
    // 


  }
  closemodels() {
    this.setState({ models: false, carlist: true });
  }
  showall() {
    this.setState({ showmore: !this.state.showmore });
  }
  onchangeTransmissonValue(event) {
    const value = event.target.value;
    this.setState({
      transmission: event.target.value,
      loading: true,
      range: 0,
    });
    const seat = this.state.seat;
    const array = this.state.equipment;
    const year = this.state.year;
    const parking = this.state.parking;
    const type = this.state.isRadio;
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const sortChoiced = this.state.sortChoiced;
    const withDriver = this.state.withDriver;
    const destination = this.state.destination;
    const departure = localStorage.getItem('userCity')


    this.props
      .searchCar({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: value,
        seat: seat,
        year: year,
        parking: parking,
        type: type,
        sortChoiced: sortChoiced,
        withDriver: withDriver,
        destination: destination,
        departure: departure,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId()
      })
      .then(() => this.setState({ loading: false }));
  }
  rangeSet() {
    this.setState({ loading: true, priceRangeIsDisabled: true });

    const seat = this.state.seat;
    const array = this.state.equipment;
    const year = this.state.year;
    const type = this.state.isRadio
    const parking = this.state.parking;
    const transmission = this.state.transmission;
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const withDriver = this.state.withDriver
    const destination = this.state.destination;
    const departure = localStorage.getItem('userCity')
    const priceRange = this.state.userRangePrice;
    // console.log('------Price range------')
    // console.log(priceRange)
    // console.log('------Price range------')
    this.props
      .priceFilter({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        price: priceRange,
        equipment: array,
        transmission: transmission,
        seat: seat,
        year: year,
        type: type,
        parking: parking,
        destination: destination,
        departure: departure,
        withDriver: withDriver,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId()
      })
      .then(() => this.setState({ loading: false }));
  }

  onchangeEquipmentValue(event) {
    const checked = event.target.checked;
    const value = event.target.value;
    var array = [];
    this.setState({ range: 0, loading: true });
    if (checked) {
      array = this.state.equipment;
      array.push(event.target.value);
      this.setState({ equipment: array });
    } else {
      array = this.state.equipment;
      var index = array.indexOf(value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ equipment: array });
      }
    }
    const parking = this.state.parking;
    const transmission = this.state.transmission;
    const seat = this.state.seat;
    const year = this.state.year;
    const type = this.state.isRadio
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const withDriver = this.state.withDriver;
    const destination = this.state.destination;
    const departure = localStorage.getItem('userCity')

    this.props
      .searchCar({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: transmission,
        seat: seat,
        year: year,
        type: type,
        parking: parking,
        sortChoiced: this.state.sortChoiced,
        withDriver: withDriver,
        destination: destination,
        departure: departure,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId()
      })
      .then(() => this.setState({ loading: false }));
  }
  onchangeSeatValue(event) {
    const value = event.target.value;
    this.setState({ seat: event.target.value, loading: true, range: 0 });
    var seat;
    if (value) {
      seat = value;
    }
    const array = this.state.equipment;
    const transmission = this.state.transmission;
    const year = this.state.year;
    const parking = this.state.parking;
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const sortChoiced = this.state.sortChoiced;
    const type = this.state.isRadio;
    const withDriver = this.state.withDriver;
    const destination = this.state.destination;
    const departure = localStorage.getItem('userCity')
    this.props
      .searchCar({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: transmission,
        seat: seat,
        year: year,
        type: type,
        parking: parking,
        sortChoiced: sortChoiced,
        withDriver: withDriver,
        destination: destination,
        departure: departure,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId()
      })
      .then(() => this.setState({ loading: false }));
  }

  // choix du type de véhicule
  handleChoiceCartype = (e) => {
    // this.setState({ isRadio: e.target.name })    
    this.setState({ loading: true });

    if (e.target.name) {
      this.setState({ isRadio: e.target.name });
    } else {
      this.setState({ isRadio: null });
    }
    // const type = this.state.isRadio;

    const transmission = this.state.transmission;
    const array = this.state.equipment;
    const seat = this.state.seat;
    const year = this.state.year;
    const parking = this.state.parking;
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const withDriver = this.state.withDriver;
    const destination = this.state.destination;
    const departure = localStorage.getItem('userCity')


    // faire la recherche
    this.props
      .searchCar({
        brands: this.getBrandsId(),
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: transmission,
        seat: seat,
        year: year,
        parking: parking,
        sortChoiced: this.state.sortChoiced,
        withDriver: withDriver,
        destination: destination,
        departure: departure,
        type: e.target?.name ?? null,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
      })
      .then(() => this.setState({ loading: false }));

  }

  onchangeYearValue(event) {
    const value = event.target.value;
    this.setState({ range: 0 });
    this.setState({ year: event.target.value, loading: true });
    const transmission = this.state.transmission;
    const array = this.state.equipment;
    const seat = this.state.seat;
    const parking = this.state.parking;
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const withDriver = this.state.withDriver;
    const destination = this.state.destination;
    const departure = localStorage.getItem('userCity')
    this.props
      .searchCar({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: transmission,
        seat: seat,
        year: value,
        parking: parking,
        sortChoiced: this.state.sortChoiced,
        withDriver: withDriver,
        destination: destination,
        departure: departure,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId()
      })
      .then(() => this.setState({ loading: false }));
  }



  handlePressBrand(item) {
    this.setState(prevState => ({
      filteredBrands: prevState.filteredBrands.map(brand => {
        if (brand._id === item._id) {
          return { ...brand, selected: !brand.selected };
        }
        return brand;
      })
    }));
    this.checkIfBrandHasSelected()

  };

  findBrands(query) {
    this.setState({ brand: query })
    if (query) {
      // Making a case insensitive regular expression
      // const regex = new RegExp(`${query}`, 'i');
      // Setting the filtered film array according the query
      const temp = this.state.allBrands.filter((brand) => brand.brandname.toLowerCase().includes(query.toLowerCase()))
      if (temp.length >= 5) {
        this.setState({ filteredBrands: copyFiveElements(temp) })
      } else {
        this.setState({ filteredBrands: temp })
      }

    } else {
      // If the query is null then return blank      
      this.setState({ filteredBrands: [] })


    }
  }
  nextLink(e) {
    this.setState({ loading: true });
    e.preventDefault();
    localStorage.setItem("checkIn", this.state.brutCheckin);
    localStorage.setItem("checkOut", this.state.brutCheckout);
    const address = localStorage.getItem("place");
    const id = localStorage.getItem("siteUser");
    const departure = localStorage.getItem('userCity')
    const withDriver = this.state.withDriver
    if (this.state.address || this.state.place) {
      const value = address.split("/");
      this.props
        .searchCar({
          lat: parseFloat(value[1]),
          lng: parseFloat(value[2]),
          id,
          withDriver: withDriver,
          goOutCityState: this.state.goOutCityState,
          departure: departure,
          checkIn: localStorage.getItem("checkIn"),
          checkOut: localStorage.getItem("checkOut"),
          brands: this.getBrandsId()
        })
        .then(() => this.setState({ loading: false }));
      this.setState({ place: value[0] });
    } else {
      this.setState({ addresserr: true, loading: false });
    }
  }

  handleSubmitBrands() {
    this.setState({ loading: true });
    const transmission = this.state.transmission;
    const array = this.state.equipment;
    const seat = this.state.seat;
    const parking = this.state.parking;
    const id = localStorage.getItem("siteUser");
    const onchangeaddress = localStorage.getItem("place");
    const onchangevalue = onchangeaddress.split("/");
    const withDriver = this.state.withDriver;
    const destination = this.state.destination;
    const departure = localStorage.getItem('userCity')
    const type = this.state.isRadio;
    this.props
      .searchCar({
        lat: parseFloat(onchangevalue[1]),
        lng: parseFloat(onchangevalue[2]),
        id,
        equipment: array,
        transmission: transmission,
        seat: seat,
        year: this.state.year,
        parking: parking,
        sortChoiced: this.state.sortChoiced,
        withDriver: withDriver,
        destination: destination,
        departure: departure,
        checkIn: localStorage.getItem("checkIn"),
        checkOut: localStorage.getItem("checkOut"),
        brands: this.getBrandsId(),
        type: type
      })
      .then(() => this.setState({ loading: false }));
  }

  render() {
    // geoLocation()
    const { t } = this.props;
    if (this.state.viewdetail && this.state.carId) {
      return <Redirect push to={`search-car/${this.state.carId}`} />;
    }
    localStorage.setItem(
      "period",
      localStorage.getItem("startperiod") +
      "%" +
      localStorage.getItem("endperiod")
    );

    const cartypesList = this.props.DashboardReducer.cartypesList;
    const featuresList = this.props.DashboardReducer.featuresList;
    var cartypesArray = [];
    var featuresArray = [];

    if (cartypesList) {
      for (let i = 0; i < cartypesList.length; i++) {
        if (this.state.showmore && i === 6) {
          break;
        }
        cartypesArray.push(
          <label className={`icon-box bg-green ${this.state.isRadio === cartypesList[i]?._id ? "cartypeSelect" : ""}`} htmlFor={cartypesList[i]?._id} key={i} >
            <input type="radio" name={cartypesList[i]?._id} id={cartypesList[i]?._id} value={cartypesList[i]?._id} onClick={(e) => this.handleChoiceCartype(e)} />
            <div className="icon-box-inner">
              <div className="vehicle-img">
                <img
                  src={
                    URL.IMAGEURL_CARTYPE + cartypesList[i].image ??
                    "https://res.cloudinary.com/monbolide/image/upload/q_auto:low/v1643000990/ProjectImage/images/voiture-monbolide_akyfyn.png"
                  }
                />
                <div className="icon-box-content">
                  <div className="icon-text">{cartypesList[i].cartype}</div>
                </div>
              </div>

            </div>
          </label>
        );
      }
    }
    if (featuresList) {
      for (let i = 0; i < featuresList.length; i++) {
        featuresArray.push(
          <div key={i} className="indent">
            <input
              type="checkbox"
              id={featuresList[i]._id}
              name="equipment"
              selected={featuresList[i].name}
              onChange={(e) => this.onchangeEquipmentValue(e)}
              className="check"
              value={featuresList[i].name}
            />
            <label htmlFor={featuresList[i]._id} >
              {" "}
              {featuresList[i].name}
            </label>
            <br />
          </div>
        );
      }
    }
    // const pricerange = this.props.SearchCarsReducer.pricerange;
    const min = this.props.SearchCarsReducer?.pricerange?.minprice;
    const max = this.props.SearchCarsReducer?.pricerange?.maxprice;
    const marks = {
      min: min,
      max: max
    };


    // if(pricerange){
    //   min = pricerange.minprice
    //   max = pricerange.maxprice
    // }
    var carList = this.props.SearchCarsReducer.carList;
    var carListArray = [];
    if (carList) {
      for (let i = 0; i < carList.length; i++) {
        var carRating = [];
        if (carList[i].rating) {
          for (let j = 0; j < carList[i].rating; j++) {
            carRating.push(
              <i
                className="fa fa-star star-icon-size"
                style={{ color: "#5ECBDD" }}
              ></i>
            );
          }
          for (let j = carList[i].rating; j < 5; j++) {
            carRating.push(<i className="fa fa-star star-icon-size"></i>);
          }
        }
        if (carList[i]?.carimage && carList[i]?.carimage !== undefined) {
          carListArray.push(
            <div className="pick-car" key={i}>
              <div className="car-card">
                <div className="car-header">
                  <div className="car-img">
                    <img
                      id={carList[i]._id}

                      src={URL.IMAGEURL + carList[i].carimage.mainimage}
                      // onError={this.src='assets/images/voiture-monbolide.png'}
                      style={{ height: "250px" }}
                      onClick={(e) => this.showmodels(e)}
                    />
                  </div>
                </div>
                <div className="car-description">
                  <div className="car-description-carname">
                    {carList[i]?.carmodel?.make?.brandname +
                      " " +
                      carList[i]?.carmodel?.model?.name}
                  </div>
                  <div className="container-car-price-distance">
                    <div className="car-price-text">
                      A partir de <span className="price-detail">{carList[i]?.price?.price} F/jour</span>
                    </div>
                    <div className="car-distance-text">
                      {formatDistance(carList[i]?.distance)}
                    </div>
                  </div>
                  {carList[i]?.requirements?.withDriver &&
                    <div className="container-car-other-detail">
                      <div className="withDriverStyle">
                        Avec chauffeur
                      </div>

                    </div>
                  }

                </div>
                <div className="car-description">
                  <div className="car-address">{carList[i]?.parking?.formatted_address}</div>
                </div>
                {/* <div className="car-main">
                  <div className="car-info">
                    <div className="car-title">
                      {carList[i]?.carmodel?.make?.brandname +
                        " " +
                        carList[i]?.carmodel?.model?.name}
                    </div>
                    <div className="containerFlexSpaceAround">
                      {carList[i]?.requirements?.withDriver &&
                        <div className="withDriverStyle">
                          Avec chauffeur
                        </div>
                      }

                      <div className="">
                        {formatDistance(carList[i].distance)}
                      </div>
                    </div>




                  </div>

                  <div className="car-price">
                    <div className="car-text-title">
                      A partir de {carList[i]?.price?.price} F/jour
                    </div>
                  </div>
                </div> */}
                <div className="car-meta">
                  {carRating}
                  <span>
                    {carList[i]?.totalratings}{" "}
                    {carList[i]?.totalratings ? <>notation</> : ""}
                  </span>
                </div>
                <div className="car-group">
                  {/* <div className="car-group-item">
                    <div className="car-pill">
                      <span className="car-pill-icon">
                        <span className="car-connect">
                          <span className="connect-icon">
                            <i className="fa fa-car connect-icon-size"></i>
                          </span>
                        </span>
                      </span>
                      Tracking Monbolide
                    </div>
                  </div> */}


                  {carList[i].snapshot === true && (
                    <div className="car-booking">
                      <div className="car-booking-pill">
                        <span className="car-booking-pill-icon">
                          <span className="car-instant">
                            <span className="instant-icon">
                              <i className="fa fa-bolt instant-icon-size"></i>
                            </span>
                          </span>
                        </span>
                        Réservation instantanée
                      </div>
                    </div>
                  )}
                </div>
                <div className="seats" >
                  {carList[i]?.fuel.type === "Manual" ? "Manuelle" : "Automatique"}
                </div>
                <div className="seats" >
                  {carList[i]?.facilities?.seats + " sièges"}
                </div>
              </div>
            </div>
          );
        }
      }
    }

    if (localStorage.getItem("period")) {
      // var data = localStorage.getItem("period").split("%");
      // let checkin = data[0];
      // let checkout = data[1];
    }
    const rating = this.props.SearchCarsReducer.rating;
    if (rating && rating !== undefined) {
      var ratingArray = [];
      for (let j = 0; j < rating; j++) {
        ratingArray.push(
          <i
            className="fa fa-star star-icon-size"
            style={{ color: "#5ECBDD" }}
          ></i>
        );
      }
      for (let j = rating; j < 5; j++) {
        ratingArray.push(<i className="fa fa-star star-icon-size"></i>);
      }
    }
    const modelcarlist = this.props.SearchCarsReducer.modelcarList;
    const reviewList = this.props.SearchCarsReducer.reviewList;
    var reviewArray = [];
    if (reviewList.length) {
      for (let i = 0; i < reviewList.length; i++) {
        if (reviewList[i].rating) {
          reviewArray.push(
            <div className="rental-review" key={i}>
              <div className="review-img">
                <img
                  src="https://res.cloudinary.com/monbolide/image/q_auto/upload/v1643000941/ProjectImage/images/images/owner_j4ymyy.png"
                  alt=""
                />
              </div>
              <div className="rental-review-content">
                <div className="rental-review-info">
                  <div className="big-text">
                    {reviewList[i]?.customerId?.firstname +
                      " " +
                      reviewList[i]?.customerId?.lastname}
                  </div>
                </div>
                <div className="rental-review-text">
                  <p>{reviewList[i]?.review}</p>
                </div>
                <div className="peugeot-car-star">
                  {ratingArray}
                  <div className="bottom-margin"></div>
                </div>
              </div>
            </div>
          );
        }
      }
    }

    var modelcarArray = [];
    if (modelcarlist && modelcarlist !== undefined) {
      for (let i = 0; i < modelcarlist.length; i++) {
        var accessories = [];
        for (let item in modelcarlist[i].feature) {
          accessories.push(
            <div className="show-option" key={item}>
              <div className="option-icon">
                <i className="fa fa-star option-icon-size"></i>
              </div>
              <div className="option-content">{item}</div>
            </div>
          );
        }
        if (
          modelcarlist[i].carimage &&
          modelcarlist[i].carimage !== undefined
        ) {
          // const ownerId = localStorage.setItem(
          //   "ownerId",
          //   modelcarlist[i].userId._id
          // );
          modelcarArray.push(
            <div className="row" key={i}>
              <div className="col-lg-8">
                <div className="carousselZone">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide carou"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        {modelcarlist[i]?.carimage?.mainimage ? (
                          <img
                            className="d-block w-100"
                            style={{ height: 300 + "px" }}
                            src={
                              URL.IMAGEURL + modelcarlist[i]?.carimage?.mainimage
                            }
                            alt=""
                          />
                        ) : (
                          <img
                            className="d-block w-100"
                            src="https://res.cloudinary.com/monbolide/image/upload/v1643000942/ProjectImage/images/no-image_stapew.png"
                            alt=""
                            onerror="this.src='https://res.cloudinary.com/monbolide/image/upload/q_auto:low/v1643000990/ProjectImage/images/voiture-monbolide_akyfyn.png'"
                          />
                        )}
                      </div>
                      <div className="carousel-item">
                        {modelcarlist[i]?.carimage?.sideimage ? (
                          <div className="carousel-item carousel-car-img">
                            <img
                              className="d-block w-100"
                              style={{ height: 300 + "px" }}
                              src={
                                URL.IMAGEURL +
                                modelcarlist[i]?.carimage?.sideimage
                              }
                              alt=""
                            />
                          </div>
                        ) : (
                          <img
                            style={{ height: 300 + "px" }}
                            className="d-block w-100"
                            src="assets/images/no-image.png"
                            alt=""
                            onerror="this.src='https://res.cloudinary.com/monbolide/image/upload/q_auto:low/v1643000990/ProjectImage/images/voiture-monbolide_akyfyn.png'"
                          />
                        )}
                      </div>
                      <div className="carousel-item">
                        {modelcarlist[i]?.carimage?.backimage ? (
                          <div className="carousel-item carousel-car-img">
                            <img
                              style={{ height: 300 + "px" }}
                              className="d-block w-100"
                              src={
                                URL.IMAGEURL +
                                modelcarlist[i]?.carimage?.backimage
                              }
                              alt=""
                            />
                          </div>
                        ) : (
                          <img
                            style={{ height: 300 + "px" }}
                            className="d-block w-100"
                            src="assets/images/no-image.png"
                            alt=""
                            onerror="this.src='https://res.cloudinary.com/monbolide/image/upload/q_auto:low/v1643000990/ProjectImage/images/voiture-monbolide_akyfyn.png'"
                          />
                        )}
                      </div>
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>

                <div className="carInfo">
                  <div className="car-header">
                    <h1 className="car-name">
                      {modelcarlist[i].carmodel?.make?.brandname}
                      {modelcarlist[i]?.carmodel?.model?.name}
                    </h1>
                    <div className="car-details">
                      <div className="car-text">
                        <div className="car-icon">
                          <i className="fa fa-map-marker map-icon-size"></i>
                        </div>
                        <span className="car-attri">
                          {/*0.99
                          <span className="dot"></span>*/}
                          {modelcarlist[i]?.number?.year}
                          <span className="dot"></span>
                          {modelcarlist[i]?.facilities?.seats} sièges
                        </span>
                      </div>
                      {rating ? (
                        <div className="peugeot-car-star">
                          {ratingArray}
                          <span className="car-count">
                            {reviewArray.length}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="car-high">
                      <div className="car-group">
                        <div className="car-group-item">
                          <div className="car-pill">
                            <span className="car-pill-icon">
                              <span className="car-icon">
                                <i className="fa fa-bolt bolt-icon-size"></i>
                              </span>
                            </span>
                            Réservation instantanée
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="connect mb">
                  <div className="connect-section">
                    <div className="open-section">
                      <div className="open-section-icon">
                        <div className="open-icon">
                          <i className="fa fa-car car-icon-size"></i>
                        </div>
                      </div>
                      <div className="car-content">
                        <div className="big-text">Monbolide Plus</div>
                        <div className="small-text">
                          C'est une voiture en libre-service !
                        </div>
                      </div>
                      <a href="#" className="flex">
                        <div className="flex-icon">
                          <i className="fa fa-exclamation-circle info-icon-size"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="location mb">
                  <div className="location-section">
                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <div className="location-text">
                          Lieu de récupération et de dépot
                        </div>
                        <div className="location-address">
                          <div className="location-icon">
                            <i className="fa fa-map-marker map-icon-size-2"></i>
                          </div>
                          <div className="location-address-content">
                            <div className="location-address-text">
                              Proche de
                            </div>
                            <div className="location-address-body">
                              <div className="body-text">
                                {modelcarlist[i]?.parking?.formatted_address}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="location-explan">
                          <div className="location-note">
                            La voiture sera garée à proximité. Vous obtiendrez
                            l'emplacement exact après la réservation.
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div className="car-map">
                          <a href="https://res.cloudinary.com/monbolide/image/upload/q_auto/v1643000974/ProjectImage/images/car/car-map_iwgzbp.png">
                            <img
                              src="https://res.cloudinary.com/monbolide/image/upload/q_auto/v1643000974/ProjectImage/images/car/car-map_iwgzbp.png"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mileage mb">
                  <div className="mileage-section">
                    <div className="mileage-text">Kilométrage inclus</div>
                    <div className="mileage-cell">
                      <div className="mileage-cell-left">
                        <div className="cell-icon">
                          <i className="fa fa-map-marker map-icon-size-3"></i>
                        </div>
                      </div>
                      <div className="mileage-main">
                        <div className="mileage-title">
                          <div className="mileage-big-text">
                            {modelcarlist[i].mileage.mileage}
                          </div>
                        </div>
                        {/* <div className="mileage-small-text">
                          Prix: {modelcarlist[i].price.price} FCFA/mi
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owner mb">
                  <div className="owner-section">
                    <div className="owner-text">Propriétaire</div>
                    <a href="#" className="car-owner">
                      <div className="car-owner-img">
                        <img
                          src="https://res.cloudinary.com/monbolide/image/upload/q_auto/v1643000941/ProjectImage/images/images/owner_j4ymyy.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="owner-big-text">
                          <span>
                            <span className="link">
                              {modelcarlist[i].userId?.firstname}{" "}
                              {modelcarlist[i].userId?.lastname}
                            </span>
                          </span>
                        </div>
                        <div>
                          <div className="peugeot-car-star">
                            {ratingArray}
                            <div className="rating-count">
                              <div className="rating-value">
                                {reviewArray.length}
                              </div>
                              <div className="rating-label"> avis</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                {/*<div className="vehicle mb">
                  <div className="vehicle-section">
                    <div className="vehicle-text">Vehicle description</div>
                      <div className="vehicle-body">
                        <p>Marion has outsourced fleet management to Hiveway, the first fleet management solution for carsharing vehicles.<br/>More informations on www.hiveway.co.<br/><br/></p>
                          <p className="more-text-vehicle">Petrol - Available 24/7 - Permit for Kensington and Chelsea<br/><br/>Enjoy our Peugeot 2008, this car is easy to drive, spacious inside with high quality finishes. Its size makes it easy to drive in the city and the low consumption making it a cheap car to rent! The vehicle is very recent and very well maintained.<br/><br/>- Vehicle Access -<br/>All the instructions to access the vehicle will be accessible 1 hour before starting the rental, through the Monbolide app. We invite you to read them carefully.<br/><br/>- Cleanliness and Security -<br/>Our cars are checked, washed and inspected regularly.<br/>However, there’s sometimes only few minutes between 2 rentals and it is everyone's responsibility to leave the vehicle clean and tidy. Fee might apply for smoking in the car, or leaving garbage or packaging. Please respect the next driver.<br/><br/>- Modification of your Rental -<br/>All rentals can be adjusted directly from the Monbolide app, even during your trip. <br/>If the change is not possible, it is because the car is not available, thank you for bringing the vehicle back in time!<br/><br/>- Contact Us -<br/>Hiveway can be reached from 8am to 10pm. We invite you to use the Monbolide messaging service and we will reply to you promptly.<br/>However, in case of emergency, you can contact us by phone. <br/>In the event of an emergency or incident, Monbolide Assistance can be reached 24/7.</p>
                      </div>
                      <button className="vehicle-btn" type="button">
                        + See full description
                      </button>
                    </div>
                </div>
                <div className="owner-condition mb">
                  <div className="owner-condition-section">
                    <div className="owner-condition-text">Owner conditions</div>
                      <div className="owner-condition-body">
                        <p>
                            This car is cleaned regularly. Yet given the current situation you are kindly asked to carry your own (non bleach) cleaning wipes/ sanitising gel to keep yourself safe by cleaning all touch points as often as you wish ( car Key - steering wheel- indicators switch - gear stick- windows switch -handbrake -door handle). let's work collectively to stop this invisible enemy.<br/><br/></p>
                            <p className="more-text-owner-condition">Be careful : Monbolide Connect rentals only. <br/>Have your smartphone ready. All rentals will be done using Monbolide Open, the smartphone opening system! In case of an issue to open the car with the app, you will need to contact Monbolide customer service team and they will be able to assist you. Please make sure you charge your mobile phone.<br/><br/>Do not forget to have your profile verified if it's your first rental.<br/>Contact Monbolide directly on +44 808 164 0601 in case of problems with the validation, we are not able to help you for that.<br/><br/>- Respect leave the vehicle as you would expect to find it (up to £50 penalty for dirt).<br/>- Non-smoking vehicle (up to £50 penalty for smoking in the car). <br/>- Report any claim that may have occurred during the rental (the vehicle is checked between each rentals).<br/>- Please do not take the equipment present in the vehicle (cleaning material, USB plug, etc).<br/><br/>Enjoy your trip !
                        </p>
                      </div>
                      <button className="owner-condition-btn" type="button">
                        + See full description
                      </button>
                    </div>
                </div>*/}
                <div className="features-option">
                  <div className="features-wrapper">
                    <div className="features-card">
                      <div className="features-section">
                        <div className="features-header">
                          Caractéristiques techniques
                        </div>
                        <div className="car-features">
                          <div className="car-features-group">
                            <div className="features-mb">
                              <div className="features-text">Moteur</div>
                              <p className="features-body">
                                {modelcarlist[i]?.fuel?.fuel}
                              </p>
                            </div>
                            <div>
                              <div className="features-text">Mileomètre</div>
                              <p className="features-body">
                                {modelcarlist[i]?.mileage?.mileage}
                              </p>
                            </div>
                          </div>
                          <div className="car-features-group">
                            <div className="features-mb">
                              <div className="features-text">
                                Boite de vitesse
                              </div>
                              <p className="features-body">
                                {modelcarlist[i]?.fuel?.type}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="options-wrapper">
                    <div className="features-card">
                      <div className="features-section">
                        <div className="features-header">
                          Options et accessoires
                        </div>
                        <div className="car-option">
                          <div className="car-group">{accessories}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="insurance mb">
                    <div className="insurance-section">
                      <div className="insurance-header">Insurance</div>
                        <div className="car-insurance">
                          <div className="car-insurance-illu"></div>
                          <div>
                            <div className="insurance-logo">
                              allianz
                            </div>
                            <div className="insurance-logo-car">
                              AA
                            </div>
                            <p className="insurance-text">L'assurance et l'assistance routière 24/7 sont incluses dans le prix.
                            </p>
                            <a href="#" className="insurance-button">
                              En savoir plus
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="insurance-section">
                        <div className="card-header-1">Conditions requises</div>
                        <ul>
                          <li className="card-item">
                            <span className="card-item-icon">
                              <div className="card-icon">
                                <i className="fa fa-check-circle tick-icon-size"></i>
                              </div>
                            </span>
                            <div className="card-content">
                              <p className="card-text">
                                25 ans et 2 ans de conduite au minimum
                              </p>
                            </div>
                          </li>
                          <li className="card-item">
                            <span className="card-item-icon">
                              <div className="card-icon">
                                <i className="fa fa-check-circle tick-icon-size"></i>
                              </div>
                            </span>
                            <div className="card-content">
                              <p className="card-text">
                                Séjour dans les pays suivants :
                              </p>
                            </div>
                          </li>
                          <p className="car-list">Côte d'Ivoire</p>
                        </ul>
                      </div>
                    </div>*/}
                <div
                  className="review mb"
                  style={{ display: reviewArray.length ? "block" : "none" }}
                >
                  <div className="review-section">
                    <div className="review-header">Avis</div>
                    <div className="text-mute">
                      <div className="inline-block">
                        <div className="rentals-count">
                          <div className="review-value">
                            {reviewList.length}
                          </div>
                          <div className="review-label"> locations</div>
                        </div>
                      </div>
                      -
                      <div className="inline-block">
                        <div>
                          <div className="rate-container">
                            <div className="peugeot-car-star">
                              {ratingArray}
                              <div className="ratings-count-value">
                                {reviewArray.length}
                              </div>
                              <div className="ratings-count-label"> avis</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="infinite-page">{reviewArray}</div>
                    <div className="rental-button" style={{ display: "none" }}>
                      <div className="rental-button-item">
                        <button type="button" className="rental-btn">
                          En savoir plus...
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 text-center">
                <div className="book">
                  <span className="value">
                    {modelcarlist[i]?.price?.price} FCFA
                  </span>
                  <br />
                  <span className="mi">
                    {modelcarlist[i]?.mileage?.mileage} inclus, puis{" "}
                    {modelcarlist[i]?.price?.price} FCFA/mi
                  </span>
                  <br />
                  <Link
                    to={"book-car/" + modelcarlist[i]._id}
                    type="button"
                    className="book-btn mt-2"
                    style={{ backgroundColor: "#004AAD" }}
                  >
                    <span className="book-btn-icon">
                      <span className="book-icon">
                        <i className="fa fa-bolt bolt-icon-size text-white"></i>
                      </span>
                    </span>
                    <span className="text-white">Louer</span>
                  </Link>
                </div>
              </div>
            </div>
          );
        }
      }
    }
    return (
      <div>
        {this.state.loading && (
          <div className="loading">
            <BounceLoader
              color="#004aad"
              loading={this.state.loading}
              css={override}
              size={50}
            />
          </div>
        )}
        <div
          className={`filterContainer ${this.state.activeFilter
            ? "filterIconActived"
            : "filterIconNotActived"
            }`}
          onClick={this.handleClickFilter}
        >
          <i className="fas fa-filter filterIcon"></i>
        </div>

        <section
          className="banner-search"
          style={{
            display: this.state.carlist ? "block" : "none",
            border: "none",
          }}
        >
          <div className="container mx-auto text-center">
            <form className="form--container">
              <div className="row search--container">
                <div className="col-lg-6 col-md-12 mb-1" >
                  <span style={{ position: "absolute", top: "10px", left: "42px", }}>  Localisation</span>
                  <Autocomplete
                    style={{ border: "none", borderRadius: "39px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                    className="form-control pt-5"
                    placeholder={t("search_placeholder")}
                    onChange={(event) => this.onSetPlace(event)}
                    onPlaceSelected={(place) => {
                      
                        this.onChangePlaceValue(place);
                        // this.setState({ addresserr: false })
                      
                      // else {
                      //   this.setState({ addresserr: true, loading: false })
                      // }

                    }}
                    value={this.state.place}
                    types={['geocode', 'establishment']}
                    componentRestrictions={{ country: "civ" }}
                  />
                </div>
                <div className="mb-1 fullWidth" style={{ position: "relative" }}>

                  <DatePicker
                    locale="fr"
                    className="form-control pt-5"
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeInputLabel="Heure:"
                    selected={this.state.brutCheckin}
                    onChange={(date) => this.handleChangeCheckin(date)}
                    showTimeInput
                    showTimeSelect
                    minDate={new Date()}
                    timeCaption="Temps"
                    placeholderText="Date de debut"
                  />
                  <span className="datePickerlabel">Début location</span>
                </div>
                <div className="mb-1 fullWidth" style={{ position: "relative" }}>
                  <DatePicker
                    locale="fr"
                    timeCaption="Temps"
                    className="form-control pt-5"
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeInputLabel="Heure:"
                    selected={this.state.brutCheckout}
                    onChange={(date) => this.handleChangeCheckout(date)}
                    showTimeInput
                    showTimeSelect
                    maxDate={this.maxDateController(this.state.brutCheckin, 21)}
                    minDate={
                      this.state.brutCheckin
                        ? this.state.brutCheckin
                        : new Date() + 1
                    }
                    placeholderText="Date de fin"
                  />
                  <span className="datePickerlabel">Fin location</span>
                </div>

                <div className="col-lg-4 col-12 col-md-6 mb-3 px-3">
                  <button
                    className="btn btn-default"
                    type="submit"
                    style={{ fontWeight: 600, borderRadius: "39px" }}
                    onClick={(e) => this.nextLink(e)}
                  >
                    {t("search")}
                  </button>
                </div>
              </div>
              {this.state.addresserr ? (
                <div className="alert alert-info">Adresse non valide</div>
              ) : (
                ""
              )}
            </form>
          </div>
        </section>
        <section
          className="car-section pt-1"
          style={{ display: this.state.carlist ? "block" : "none" }}
        >
          <div className="container-fluid pr-1 pl-0">
            <div className="row">
              <div
                className={`${!this.state.isSmartphone ? "col-lg-3 col-md-4" : ""
                  }  animate__animated ${this.state.activeFilter
                    ? "show-left-side animate__slideInLeft"
                    : "hide-left-side"
                  } left-side`}
              >
                <div className="filter ">
                  <div className="filter-side">
                    <div className="filter-card">
                      <div className="filter-section">
                        {/* <div className="filter-container">
                          <label className="filter-share">
                            <div className="filter-share-icon">
                              <span className="filter-icon">
                                <i className="fa fa-car car-icon-size"></i>
                              </span>
                            </div>
                            <div className="filter-box">
                              <div className="filter-big-text">
                                Monbolide Pro
                              </div>
                              <div className="filter-small-text">
                                véhicules d'agences de location
                              </div>
                            </div>
                            <div className="filter-btn">
                              <input
                                type="checkbox"
                                className="filter-btn-input"
                                name="filter"
                              />
                              <span className="filter-btn-click"></span>
                            </div>
                          </label>
                        </div> */}
                        <div className="filter-side">
                          <div className="filter-text-section">
                            MARQUES DE VEHICULE {this.state.hasSelectedBrand && <div style={{ transition: "1s ease-in-out all" }}><button onClick={() => this.initializeBrands()} className="cancelButtonCartypeFilter">Annuler</button></div>}
                          </div>

                          <div style={{ height: 'auto', overflow: 'auto' }}>
                            {/* <div className="icon-boxs">{cartypesArray}</div> */}
                            <div>
                              <input type="text" value={this.state.brand} placeholder="Saisir la marque de véhicule"
                                className="input-brand" onChange={(e) => {
                                  this.findBrands(e.target.value);
                                }} />
                            </div>

                            <div className="container-brand-list" >
                              {/* Liste des dix premières marques */}
                              {this.state.filteredBrands.map((brand) => (
                                <button key={brand._id} className="buttonBrand"
                                  style={{
                                    color: brand.selected ? 'white' : ThemeStyle.color,
                                    backgroundColor: brand.selected ? ThemeStyle.layColor : '#fafafa'
                                  }}
                                  id={brand._id} value={brand} onClick={(e) => {
                                    this.handlePressBrand(brand)


                                  }} >{brand.brandname}</button>
                              ))}
                            </div>
                            <hr style={{ opacity: .4, marginTop: "0px" }} />
                            <div className="show" style={{ display: 'flex', justifyContent: "flex-end", width: '100%' }}>
                              <button className="button-brandname" onClick={() => this.handleSubmitBrands()}>Valider</button>
                            </div>

                          </div>
                        </div>

                        <div className="filter-side">
                          <div className="filter-text-section">
                            Type de véhicule {this.state.isRadio && <div style={{ transition: "1s ease-in-out" }}><button disabled={!this.state.isRadio} onClick={(e) => this.handleChoiceCartype(e)} className="cancelButtonCartypeFilter">Annuler</button></div>}
                          </div>
                          {/* {this.state.isRadio && <div style={{ display: "flex", paddingBottom: "1rem", justifyContent: "right", transition: "1s ease-in-out" }}><button disabled={!this.state.isRadio} onClick={() => this.setState({ isRadio: null })} className="cancelButtonCartypeFilter">Annuler</button></div>} */}
                          <div className="container-icon-boxs">
                            <div className="icon-boxs">{cartypesArray}</div>
                            <button
                              id="hide-view"
                              className="button-type"
                              onClick={() => this.showall()}
                            >
                              {this.state.showmore && (
                                <span>
                                  <i className="fas fa-chevron-circle-down"></i>
                                </span>
                              )}
                              {!this.state.showmore && (
                                <span>
                                  <i className="fas fa-chevron-circle-up"></i>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>

                        <div className="filter-side">
                          <div className="filter-header">Type de location</div>
                          <FormControl>
                            {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={this.state.withDriver}
                              onChange={(event) =>
                                this.handleChangeTypeTrips(event)
                              }
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio size="small" />}
                                label="avec chauffeur"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio size="small" />}
                                label="sans chauffeur"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div className="filter-side">
                          <div className="filter-header">Trier par {this.state.sortChoiced && <div style={{ transition: "1s ease-in-out opacity" }}><button disabled={!this.state.sortChoiced} onClick={(e) => this.handleChangeSortBy(e)} className="cancelButtonCartypeFilter">Annuler</button></div>}</div>
                          <FormControl>
                            {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={this.state.sortChoiced}
                              onChange={(event) =>
                                this.handleChangeSortBy(event)
                              }
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio size="small" />}
                                label="Plus récentes"
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio size="small" />}
                                label="Plus anciennes"
                              />
                              <FormControlLabel
                                value="3"
                                control={<Radio size="small" />}
                                label="Prix croissants"
                              />
                              <FormControlLabel
                                value="4"
                                control={<Radio size="small" />}
                                label="Prix décroissants"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>

                        <div className="filter-side">
                          <div className="filter-header">Prix <div><button className="validatePriceRange" disabled={this.state.priceRangeIsDisabled} onClick={() => this.rangeSet()}>Valider</button></div> </div>
                          <div >
                            <Slider min={min} max={max} marks={[min, max]} onChange={(value) => {
                              // console.log(value)
                              this.setState({ userRangePrice: value, priceRangeIsDisabled: false })
                            }} step={1000} range tooltip={{ open: false }} defaultValue={[min, max]} />
                            <div className="container-input">
                              <div className="box-input">
                                <InputNumber min={this.state.range[0]} max={this.state.userRangePrice.length > 0 ? this.state.userRangePrice[1] : this.state.price[1]} defaultValue={this.state.range[0]} value={this.state.userRangePrice.length > 0 ? this.state.userRangePrice[0] : this.state.price[0]}
                                  onChange={(value) => {
                                    this.setState(prevState => {
                                      const newArray = [...prevState.userRangePrice]; // Créez une copie du tableau existant
                                      newArray[0] = value; // Mettez à jour le premier élément
                                      if (newArray[1] === null || newArray[1] === undefined) {
                                        newArray[1] = this.state.range[1]
                                      }
                                      // newArray[0] = value;
                                      return { userRangePrice: newArray };
                                    });

                                    // this.state.userRangePrice[0] = value
                                  }} />
                              </div>
                              <div className="hyphen-divider">-</div>
                              <div className="box-input">
                                <InputNumber min={this.state.price[0]} max={this.state.price[1]}
                                  defaultValue={this.state.range[1]}
                                  value={this.state.userRangePrice.length > 0 ? this.state.userRangePrice[1] : this.state.price[1]}
                                  onChange={(value) => {
                                    this.setState(prevState => {
                                      const newArray = [...prevState.userRangePrice]; // Créez une copie du tableau existant
                                      if (newArray[0] === null || newArray[0] === undefined) {
                                        newArray[0] = this.state.range[0];
                                      }
                                      newArray[1] = value; // Mettez à jour le premier élément
                                      return { userRangePrice: newArray };
                                    });
                                  }} />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="filter-size">
                          {!this.state.filters ? (
                            <button
                              className="button-more"
                              id="btn-more"
                              onClick={() => this.showfilters()}
                            >
                              <span>Plus de filtre</span>
                            </button>
                          ) : (
                            ""
                          )}

                          <section
                            className="hide-seat"
                            id="more-filter"
                            style={{
                              display: this.state.filters ? "block" : "none",
                            }}
                          >
                            <div className="seat">
                              <div className="seat-header">
                                Nombre de sièges {this.state.seat && <div style={{ transition: "1s ease-in-out" }}><button disabled={!this.state.seat} onClick={(e) => this.onchangeSeatValue(e)} className="cancelButtonCartypeFilter">Annuler</button></div>}
                              </div>
                              <div>
                                <select
                                  name="seat-option"
                                  className="select_input"
                                  value={this.state.seat}
                                  onChange={(e) => this.onchangeSeatValue(e)}
                                >
                                  <option value="" selected={!this.state.seat}>Combien de sièges ?</option>
                                  {/* <option value="" selected={!this.state.seat}>Tout</option> */}
                                  <option value="1">1 siège</option>
                                  <option value="2">2 sièges</option>
                                  <option value="3">3 sièges</option>
                                  <option value="4">4 sièges</option>
                                  <option value="5">5 sièges</option>
                                  <option value="6">6 sièges</option>
                                  <option value="7">7 sièges</option>
                                </select>
                              </div>
                            </div>

                            <div className="vehicle">
                              <div className="vehicle-header">
                                Année du véhicule
                              </div>
                              <div>
                                <select
                                  name="vehicle-option"
                                  className="select_input"
                                  value={this.state.year}
                                  onChange={(e) => this.onchangeYearValue(e)}
                                >
                                  <option value="">Année de fabrication</option>
                                  <option value="">Tout</option>
                                  <option value="2010">2010</option>
                                  <option value="2011">2011</option>
                                  <option value="2012">2012</option>
                                  <option value="2013">2013</option>
                                  <option value="2014">2014</option>
                                  <option value="2015">2015</option>
                                  <option value="2016">2016</option>
                                  <option value="2017">2017</option>
                                  <option value="2018">2018</option>
                                  <option value="2019">2019</option>
                                  <option value="2020">2020</option>
                                  <option value="2021">2021</option>
                                </select>
                              </div>
                            </div>
                            <div className="transmission">
                              <div className="transmission-header">
                                Transmission
                              </div>
                              <div>
                                <select
                                  name="transmission-option"
                                  className="select_input"
                                  value={this.state.transmission}
                                  onChange={(e) =>
                                    this.onchangeTransmissonValue(e)
                                  }
                                >
                                  <option value="" selected={!this.state.transmission}>Tout</option>
                                  <option value="">Boîte de vitesses</option>
                                  <option value="manual">Manuelle</option>
                                  <option value="automatic">Automatique</option>
                                </select>
                              </div>
                            </div>

                            {/* <div className="engine">
                              <div className="engine-header">Moteur</div>
                              <div>
                                <input
                                  type="checkbox"
                                  id="engine1"
                                  name="engine1"
                                  value=""
                                  className="check"
                                />
                                <label
                                  htmlFor="vehicle1"
                                  style={{ color: "#004AAD" }}
                                >
                                  {" "}
                                  Électrique
                                </label>
                                <br />
                                <input
                                  type="checkbox"
                                  id="engine2"
                                  name="engine2"
                                  value=""
                                  className="check"
                                />
                                <label
                                  htmlFor="vehicle2"
                                  style={{ color: "#004AAD" }}
                                >
                                  {" "}
                                  Hybride
                                </label>
                                <br />
                              </div>
                            </div> */}

                            <div className="equipment">
                              <div className="equipment-header">
                                Équipements
                              </div>
                              {featuresArray}
                            </div>

                            {/* <div className="acceptance">
                              <div className="acceptance-header">
                                Taux d'acceptation
                              </div>
                              <select
                                name="acceptance-option"
                                className="select_input"
                              >
                                <option value="">Au-dessus de 30%.</option>
                                <option value="">Tout</option>
                              </select>
                            </div> */}
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                {carListArray.length > 0 ? (
                  <>
                    <div className="car-search px-2">
                      <div className="car-search-result ">
                        Resultats 1-{carListArray.length}
                      </div>
                      <div className="car-search-action">
                        <div className="button">
                          <button
                            className="car-button"
                            type="button"
                            onClick={() => this.viewTest()}
                            id="btn"
                            value="map"
                          >
                            {!this.state.mapview ? (
                              <i className="fas fa-map-marked"></i>
                            ) : (
                              <i className="fa fa-list"></i>
                            )}
                            {!this.state.mapview ? (
                              <> Vue map </>
                            ) : (
                              <> Vue liste </>
                            )}
                          </button>
                        </div>
                      </div>

                    </div>
                  </>

                ) : (
                  ""
                )}
                <div
                  className="car-list"
                  id="grid"
                  style={{ display: !this.state.mapview ? "block" : "none", marginBottom: "100px" }}
                >
                  {carListArray.length === 0 ? (
                    <div className="car-sec">
                      <img
                        src="https://res.cloudinary.com/monbolide/image/upload/q_auto/v1643000986/ProjectImage/images/car-img_vocfo7.png"
                        style={{ width: "60%" }}
                        className="car-img"
                        alt=""
                      />
                      <h2>Aucune voiture pour cette recherche</h2>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="pick-cars">{carListArray}</div>
                </div>
                <div
                  className="map-view"
                  id="map"
                  style={{ display: this.state.mapview ? "block" : "none", marginBottom: "200px" }}
                >
                  {carList &&
                    <div style={{ width: "100%", height: "100%" }}>

                      <Map cars={carList} coordinate={this.state.coordinate} />


                    </div>
                  }

                </div>
              </div>



            </div>
          </div>
        </section>
        <div
          className="modal-body"
          style={{ display: this.state.models ? "block" : "none" }}
        >
          <div className="modal-section">
            <div className="container mt">{modelcarArray}</div>
            <div
              className="close-btn close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.closemodels()}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
    SearchCarsReducer: state.SearchCarsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchCar: AC_SEARCH_CAR,
      priceFilter: AC_PRICE_FILTER,
      modelCar: AC_MODEL_CAR,
      addTrip: AC_ADD_TRIP,
    },
    dispatch
  );
}

const CarlistComponent = withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Carlist)
);

export default CarlistComponent;
