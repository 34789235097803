import React from "react";
import { Link } from "react-router-dom";

class Mobility extends React.Component{
    render(){
        return(
            <section className="list-car">
	      	<div className="container">
	      		<div className="row">
	      			<div className="col-lg-6">
						<div style={{display: "flex", height: "100%",justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
							<h2 className="heading mb-5">Nous sommes actuellement présent qu'en Côte d'Ivoire </h2>
							<p className="sm-centred">Nous travaillons activement pour être très rapidement présent dans toute l'Afrique de l'Ouest</p>
						</div>
	      				
	      			</div>
	      			<div className="col-lg-6">
	      				<div className="owner-img">
	      					<img src="https://res.cloudinary.com/monbolide/image/upload/f_auto/q_10/v1643000957/ProjectImage/images/country_fyx5xi.png" alt=""/>
	      				</div>
	      			</div>
	      		</div>
	      	</div>
	      </section>
        )
    }
}

export default Mobility;
