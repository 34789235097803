import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

class RentCar extends React.Component {
  render() {
    const configurationOptions = this.props.DashboardReducer.configurationList;
    var name = "louer un véhicule";
    var optionsList = [];
    if (configurationOptions) {
      var optionListOptions =
        this.props.DashboardReducer.configurationList.optionList;
      if (optionListOptions !== undefined && optionListOptions.length > 0) {
        for (var i = 0; i < optionListOptions.length; i++) {
          optionsList.push(
            <div key={i}>
              <h3 className="sub-head">{optionListOptions[i].name}</h3>
              <p className="para">{optionListOptions[i].description}</p>
            </div>
          );
        }
      }
    }
    return (
      <section
        className="rent-car"
        style={{ display: optionsList.length > 0 ? "block" : "none" }}
      >
        <div className="container">
          <h3 className="heading mt-1 pt-5">{`Louer rapidement un véhicule avec Monbolide`}</h3>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 img-ord">
                <div className="map-img">
                  {/* <img
                    src="https://res.cloudinary.com/monbolide/image/upload/v1643000944/ProjectImage/images/rent-a-car_zjlpw6.png"
                    alt=""
                  /> */}
                  <img
                    src="https://res.cloudinary.com/monbolide/image/upload/v1696687210/ProjectImage/images/get-img/rentcar_image_kmtdnd.jpg"
                    alt="rentcar"
                  />
                  
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                {optionsList}
              <div>
                <Link to="/questions" className="work-btn mb-5">
                  Voir comment cela fonctionne
                </Link>
              </div>
            </div>

          </div>
           
        </div>        
  </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    DashboardReducer: state.DashboardReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const RentCarComponent = connect(mapStateToProps, mapDispatchToProps)(RentCar);

export default RentCarComponent;
