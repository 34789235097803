import React from "react";
// import { Emailvalidate, Validpassword } from "../../common/validate";
import {
  AC_AUTHORIZATION,
  AC_ACCOUNT_CREATION,
  AC_FACEBOOK_AUTH,
  AC_GOOGLE_AUTH,
} from "../../actions/accountaction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import GoogleLogin from "react-google-login";

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signup: false,
      signin: true,
      signupEmail: true,
      loginemail: "",
      loginpassword: "",
      loginemailError: false,
      loginpasswordError: false,
      validloginemailError: false,
      validloginpasswordError: false,
    };
    this.signinValidateForm = this.signinValidateForm.bind(this);
  }
  
  onchangeValue(e) {
    const name = e.target.name;
    const value = e.target.value;
    // const check = e.target.checked;
    // const validemailcheck = Emailvalidate(value);
    // const validpasswordcheck = Validpassword(value);

    if (name === "loginemail") {
      if (value) {
        this.setState({
          loginemail: value,
          loginemailError: false,
        });      
    } else {
      this.setState({
        loginemail: value,
        loginemailError: true,
      });
    }
  }
  if(name === "loginpassword") {
  if (value) {
    this.setState({ loginpassword: value, loginpasswordError: false });
  } else {
    this.setState({ loginpassword: value, loginpasswordError: true });
  }
}
  }

signinValidateForm(e) {

  e.preventDefault();
  // const loginemail = this.state.loginemail.toLowerCase().trim();
  const loginemail = this.state.loginemail;

  const loginpassword = this.state.loginpassword;
  const loginemailError = this.state.loginemailError;
  const loginpasswordError = this.state.loginpasswordError;
  const validloginemailError = this.state.validloginemailError;
  // const validloginpasswordError = this.state.validloginpasswordError;
  const validemailError = this.state.validemailError;
  const validpasswordError = this.state.validpasswordError;

  if (loginemail) {
    this.setState({ loginemailError: false });
  } else {
    this.setState({ loginemailError: true });
  }
  if (loginpassword) {
    this.setState({ loginpasswordError: false });
  } else {
    this.setState({ loginpasswordError: true });
  }

  //Sign in
  if (
    loginemail &&
    loginpassword &&
    !loginemailError &&
    !loginpasswordError &&
    !validloginemailError &&
    !validemailError &&
    !validpasswordError
  ) {
    const formData = {
      email: loginemail,
      password: loginpassword,
    };    
    this.props.SignIn(formData);

  }
}

//submit connect with facebook
responseFacebook = (response) => {
  this.props.FacebookAuth(response);
};

responseGoogle = (response) => {
  this.props.GoogleAuth(response);
};

navigate(e) {
  const path = e.target.id;
  const name = e.target.name;
  if (path === "pills-home-tab") {
    this.setState({ signin: true, signup: false });
  }
  if (path === "pills-profile-tab") {
    this.setState({ signin: false, signup: true });
  }
  if (name === "signin") {
    this.setState({ signin: true, signup: false });
  }
  if (name === "signup") {
    this.setState({ signin: false, signup: true });
  }
  if (name === "emailSignup") {
    this.setState({ signupEmail: false });
  }
}
render() {
  return (
    <div className="main-div">
      <div className="row mx-0">
        <div className="col-lg-12 p-0 m-0">
          <div className="main-layout" style={{ height:"100%", display:"flex" }}>
            <div className="tab-content px-3 py-3" id="pills-tabContent" style={{margin: "auto", backgroundColor: "white", borderRadius: "10px" }}>
              <div
                className={
                  this.state.signin
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="signupTitle pb-3">connexion</div>
                <form action="#" onSubmit={this.signinValidateForm}>
                  <input
                    type="text"
                    placeholder="Email, Numéro de téléphone"
                    className="form-control form-pad"
                    name="loginemail"
                    value={this.state.loginemail}
                    onChange={(e) => this.onchangeValue(e)}
                  />
                  {this.state.loginemailError ? (
                    <label className="text-danger">
                      L'email est obligatoire
                    </label>
                  ) : (
                    ""
                  )}
                  {this.state.validloginemailError ? (
                    <label className="text-danger">
                      L'email est invalide
                    </label>
                  ) : (
                    ""
                  )}
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    className="form-control form-pad mt-4"
                    name="loginpassword"
                    value={this.state.loginpassword}
                    onChange={(e) => this.onchangeValue(e)}
                  />
                  {this.state.loginpasswordError ? (
                    <label className="text-danger">
                      Le mot de passe est obligatoire
                    </label>
                  ) : (
                    ""
                  )}
                  {this.state.validloginpasswordError ? (
                    <label className="text-danger">
                      Mot de passe invalide
                    </label>
                  ) : (
                    ""
                  )}
                  <Link
                    to="/forgot-password"
                    className="mt-3 d-block"
                    style={{ color: "#004AAD", fontSize: ".8rem", fontStyle: 'italic' }}
                  >
                    J'ai oublié mon mot de passe !
                  </Link>
                  <input
                    type="submit"
                    value="Se connecter"
                    className="form-control sign-btn"
                    onClick={this.signinValidateForm}
                  />
                </form>
                {/* <div className="or">ou</div> */}

                {/* <GoogleLogin
                  clientId="253350058919-3ug5rfds27e7khsb9l5ithki6opl2k79.apps.googleusercontent.com"
                  autoLoad={false}
                  render={(renderProps) => (
                    <a
                      href="#"
                      className="social-log"
                      onClick={renderProps.onClick}
                    >
                      <span className="Icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 18 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="M9,3.48 C10.69,3.48 11.83,4.21 12.48,4.82 L15.02,2.34 C13.46,0.89 11.43,0 9,0 C5.48,0 2.44,2.02 0.96,4.96 L3.87,7.22 C4.6,5.05 6.62,3.48 9,3.48 L9,3.48 Z"
                              fill="#EA4335"
                            ></path>
                            <path
                              d="M17.64,9.2 C17.64,8.46 17.58,7.92 17.45,7.36 L9,7.36 L9,10.7 L13.96,10.7 C13.86,11.53 13.32,12.78 12.12,13.62 L14.96,15.82 C16.66,14.25 17.64,11.94 17.64,9.2 L17.64,9.2 Z"
                              fill="#4285F4"
                            ></path>
                            <path
                              d="M3.88,10.78 C3.69,10.22 3.58,9.62 3.58,9 C3.58,8.38 3.69,7.78 3.87,7.22 L0.96,4.96 C0.35,6.18 0,7.55 0,9 C0,10.45 0.35,11.82 0.96,13.04 L3.88,10.78 L3.88,10.78 Z"
                              fill="#FBBC05"
                            ></path>
                            <path
                              d="M9,18 C11.43,18 13.47,17.2 14.96,15.82 L12.12,13.62 C11.36,14.15 10.34,14.52 9,14.52 C6.62,14.52 4.6,12.95 3.88,10.78 L0.97,13.04 C2.45,15.98 5.48,18 9,18 L9,18 Z"
                              fill="#34A853"
                            ></path>
                          </g>
                        </svg>
                      </span>
                      Se connecter via Google
                    </a>
                  )}
                  buttonText="Login"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  cookiePolicy={"single_host_origin"}
                /> */}
                {/* <a href="#" className="social-log bg-social">
                    <span className="Icon I-clr">
                      <svg
                        width="29"
                        height="30"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.5 4.875v13.75c0 .52-.182.964-.547 1.328a1.808 1.808 0 0 1-1.328.547h-3.32v-6.914h2.343L18 10.93h-2.695V9.25c0-.417.078-.73.234-.938.208-.234.56-.351 1.055-.351H18V5.617c-.547-.078-1.224-.117-2.031-.117-1.042 0-1.869.306-2.48.918-.613.612-.919 1.465-.919 2.559v1.953h-2.383v2.656h2.383V20.5H4.875c-.52 0-.964-.182-1.328-.547A1.808 1.808 0 0 1 3 18.625V4.875c0-.52.182-.964.547-1.328A1.808 1.808 0 0 1 4.875 3h13.75c.52 0 .964.182 1.328.547.365.364.547.807.547 1.328z"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    Se connecter via Facebook
                  </a> */}
                {/* <FacebookLogin
                  appId="365944231624099"
                  autoLoad={false}
                  callback={this.responseFacebook}
                  isMobile={false}
                  render={(renderProps) => (
                    <a
                      href="#"
                      className="social-log bg-social"
                      onClick={renderProps.onClick}
                    >
                      <span className="Icon I-clr">
                        <svg
                          width="29"
                          height="30"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.5 4.875v13.75c0 .52-.182.964-.547 1.328a1.808 1.808 0 0 1-1.328.547h-3.32v-6.914h2.343L18 10.93h-2.695V9.25c0-.417.078-.73.234-.938.208-.234.56-.351 1.055-.351H18V5.617c-.547-.078-1.224-.117-2.031-.117-1.042 0-1.869.306-2.48.918-.613.612-.919 1.465-.919 2.559v1.953h-2.383v2.656h2.383V20.5H4.875c-.52 0-.964-.182-1.328-.547A1.808 1.808 0 0 1 3 18.625V4.875c0-.52.182-.964.547-1.328A1.808 1.808 0 0 1 4.875 3h13.75c.52 0 .964.182 1.328.547.365.364.547.807.547 1.328z"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      Se connecter via Facebook
                    </a>
                  )}
                /> */}

                <div className="text-center">
                  Vous n'avez pas encore de compte ?
                  <Link
                    to="/sign-up"
                    name="signup"
                    style={{ color: "#004AAD" }}
                    onClick={(e) => this.navigate(e)}
                  >
                    {" "}
                    S'inscrire
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-6  p-0 m-0">
          <div className="signin-img">            
          </div>
        </div> */}
      </div>
    </div>
  );
}
}

function mapStateToProps(state) {
  return {
    AccountReducer: state.AccountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      SignUp: AC_ACCOUNT_CREATION,
      SignIn: AC_AUTHORIZATION,
      FacebookAuth: AC_FACEBOOK_AUTH,
      GoogleAuth: AC_GOOGLE_AUTH,
    },
    dispatch
  );
}

const SigninComponent = connect(mapStateToProps, mapDispatchToProps)(Signin);

export default SigninComponent;
