import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerClusterer,
  Circle,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import API from "../../common/api";
// import { Link } from "react-router-dom";
import "./Map.css";
// import SearchCarDetail from '../../components/searchcar/searchcar-detail';
// Import custom styles to customize the style of Google Map
const styles = require("./GoogleMapStyle.json");

function MyComponent({ cars, coordinate }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCl5l0BCxLZ9FyPlUb6mDufEdO-NH91d5c",
    region: "CI",
  });

  let mappedCars = cars?.map((car) => ({
    location: {
      infoCar: {
        address: car.billaddress,
        id: car._id,
        brandname: car.carmodel?.make?.brandname,
        modelname: car.carmodel?.model?.name,
        description: car.carmodel?.model?.description,
        carimage: API.IMAGEURL + car.carimage?.mainimage,
        logo: API.IMAGEURL + car.carmodel?.model?.description,
        price: car?.price?.price,
        feature: car.feature,
        profilImage: API.IMAGEURL + car.carimage?.mainimage,
      },
      icon: {
        url: "https://res.cloudinary.com/monbolide/image/upload/q_auto/v1643000944/ProjectImage/images/voiture-de-sport_lualio.png",
        scaledSize: new window.google.maps.Size(50, 50), // sca
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0),
      },
      lat: car?.parking?.location?.lat,
      lng: car?.parking?.location.lon,
    },
  }));

  // const [map, setMap] = React.useState(null)
  // const [center, setCenter] = React.useState(null);
  const [selected, setSelected] = React.useState(null);

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {

  //   setMap(null)
  //   setCenter(null)
  // }, [])

  return isLoaded ? (
    <GoogleMap
      options={{
        styles: styles,
      }}
      // mapContainerStyle={containerStyle}
      mapContainerStyle={{
        minHeight: "100vh",
        width: "100%",
      }}
      zoom={10}
      center={{ lat: coordinate.lat ? coordinate.lat : 5.358834, lng: coordinate.lng ? coordinate.lng : -3.931909 }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <MarkerClusterer>
        {(clusterer) =>
          mappedCars.map(({ location }, index) => (
            <Marker
              animation={window.google.maps.Animation.DROP}
              icon={location.icon}
              key={index}
              position={location}
              clusterer={clusterer}
              onClick={() => setSelected(location)}
              title={
                location.infoCar.brandname +
                " " +
                location.infoCar.modelname +
                " " +
                location.infoCar.description
              }
            />
          ))
        }
      </MarkerClusterer>
      <Circle
        center={{ lat: coordinate.lat ? coordinate.lat: 5.358834, lng: coordinate.lng ? coordinate.lng : -3.931909 }} // Coordonnées du centre du cercle
        radius={1500} // Rayon du cercle en mètres
        options={{
          fillColor: 'blue', // Couleur de remplissage
          fillOpacity: 0.3,  // Opacité de remplissage
          strokeColor: 'blue', // Couleur de bordure
          strokeOpacity: 0.8, // Opacité de bordure
          strokeWeight: 2,    // Épaisseur de bordure
        }}

    />
      {selected ? (
        <InfoWindow
          position={{
            lat: selected?.lat || 5.358834,
            lng: selected?.lng || -3.931909,
          }}
          onCloseClick={() => {
            setSelected(null);
          }}
        >
          <div style={{ minWidth: 150, maxWidth: 350, textAlign: "center" }}>
            <a>
              <div className="card pb-3">
                <img
                  src={selected.infoCar.profilImage}
                  alt={selected.infoCar.modelname}
                  style={{ width: "100%" }}
                />
                <div className="infoTitle pt-2 pb-1">
                  {/* <div className="chip">
                    <img src="img_avatar.jpg" alt="" width="96" height="96" />
                    {selected.infoCar.modelname} {selected.infoCar.brandname}
                  </div> */}
                  <div className="chip">
                    <div className="chip-head">
                      <i className="fas fa-tag"></i>
                    </div>
                    <div className="chip-content">
                      {selected.infoCar.modelname} {selected.infoCar.brandname}
                    </div>
                  </div>
                </div>
                <div className="price">
                  {selected.infoCar.price}XOF pour 1 jour
                </div>
                {selected.infoCar.feature.Automatisation && (
                  <span>Automatique </span>
                )}
                {selected.infoCar.feature.Climatisation && (
                  <span>Climatisé </span>
                )}
                {selected.infoCar.feature["Sièges pour enfants"] && (
                  <span>Sièges pour enfant </span>
                )}
                {/* <button>Louer</button> */}

                {/* <div> <b> {selected.infoCar.brandname} </b><small>{selected.infoCar.modelname} de type {selected.infoCar.description}</small></div> */}
                {/* <div className='py-1'><small>{selected.infoCar.price}XOF/jour</small></div> */}
                {/* <div className="polaroid">
              <img src={selected.icon.url} alt={selected.infoCar.brandname + selected.infoCar.modelname} style={{width:'100%'}} />
                <div className="description">
                  <p>Northern Lights</p>
                </div>
            </div> */}
                {/* <img src={selected.icon.url} alt="" style={{ width: '100%', borderRadius: '7px', }} /> */}
                {/* <div><Link to={`search-car/${selected.infoCar.id}`}  style={{ width: '100%', margin: '0 auto' }} className='btn btn-primary btn-sm'><i className="fas fa-car"></i> Choisir </Link></div> */}
                {/* <div style={{ width: '100%' }}><a style={{ width: '100%', margin: '0 auto' }} className='btn btn-primary btn-sm'><i className="fas fa-car"></i> Choisir </a></div> */}
              </div>
            </a>
          </div>
        </InfoWindow>
      ) : null}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
