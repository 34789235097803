import React from "react";
import { Redirect } from "react-router-dom";
import Congratulation from "../confetti/Confetti";
class ReadyToRent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previous: false,
      next: false,
    };
    this.validateForm = this.validateForm.bind(this);
    this.previous = this.previous.bind(this);
  }
  validateForm() {
    localStorage.removeItem("formId", "");
    // window.location="/hired-cars";
    const redirecTo = "/hired-cars";
    window.location.href = window.location.origin + redirecTo;
  }

  previous() {
    this.setState({ previous: true });
  }

  render() {
    // const next     = this.state.next;
    const previous = this.state.previous;
    if (previous) {
      return <Redirect to="/add-car-image" />;
    }
    return (
      <div className="woohoo-form-layout">
        <Congratulation />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-lg-offset-3 col-md-12">
              <div className="box">
                <div className="list-my-car" style={{ width: "123%", marginLeft: "-40px" }}>
                  <div className="list-top">
                    <div className="progress" style={{ height: "8px" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: "100%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="list-car-card">
                    {/* <div className="woohoo-img">
                      <img src="https://res.cloudinary.com/monbolide/image/upload/q_auto:eco/v1643000945/ProjectImage/images/woohoo_gbeutn.png" alt='woohoo_gbeutn' />
                    </div> */}
                    <div className="list-car-card">
                      <div className="woohoo-img">
                        <img
                          src="https://res.cloudinary.com/monbolide/image/upload/v1643000945/ProjectImage/images/woohoo_gbeutn.png"
                          alt="woohoo_gbeutn"
                        />
                      </div>
                      <div className="woohoo-title">Woohoo!</div>
                      <div className="woohoo-line-1">
                        Votre voiture a été enregistrée avec succès.
                      </div>

                    </div>
                    <div className="list-bottom">
                      <div className="btn-nav">
                        <button
                          className="prev-button mr-2"
                          onClick={this.previous}
                        >
                          <span className="cobalt-Icon">
                            <svg
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M14.811 17.343L9.468 12l5.343-5.355L13.166 5l-7 7 7 7z"></path>
                            </svg>
                          </span>
                          Précédent
                        </button>
                        <button
                          className="Woohoo-btn"
                          onClick={this.validateForm}
                        >
                          Préparez-vous à louer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReadyToRent;
