import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AC_GET_CONFIGURATION } from "../../actions/configurationaction";

class BusinessSection extends React.Component {
  componentDidMount() {
    this.props.ConfigList({ slug: "monbolide-for-business" });
  }
  render() {
    const options = this.props.ConfigurationReducer.ConfigValue;
    const optionList = [];
    if (options) {
      for (var i = 0; i < options.length; i++) {
        optionList.push(
          <>
            <h3 className="business-title mb-16">{options[i].name}</h3>
            <p className="business-body">{options[i].description}</p>
          </>
        );
      }
    }
    return (
      <section className="business-section">
        <div className="container">
          <div className="big-text mb-large">
            <h2>
            Monbolide pour les entreprises : Des solutions intelligentes pour
              démarrer
            </h2>
          </div>
          <div className="small-text mb-large">
            <p>
              Ne vous fatiguez plus. Pas d'abonnements, pas de files d'attente,
              pas de paperasse. Accès 24h/24 et 7j/7 aux voitures voisines que
              vous pouvez déverrouiller avec votre téléphone.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-16">
              <div className="card">
                <div className="card-img" style={{ height: 200 }}>
                  <img
                    src="https://res.cloudinary.com/monbolide/image/upload/q_auto:low/v1643000990/ProjectImage/images/voiture-monbolide_akyfyn.png"
                    alt="Monbolide véhicule"
                  />
                </div>
                {optionList[0]}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    ConfigurationReducer: state.ConfigurationReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ConfigList: AC_GET_CONFIGURATION }, dispatch);
}
const BusinessSectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessSection);

export default BusinessSectionComponent;
